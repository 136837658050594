import React, { useState, useRef, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, Mic, Smile, Heart, Paperclip, ChevronDown, Play, Music, Podcast, MapPin, UserCheck, AlertTriangle, Feather, Clipboard, MessageSquare, CloudLightning, Earth } from 'lucide-react';
import { authFetch } from '../utils/authFetch';
import { useLocation } from 'react-router-dom';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

const modes = [
  { name: 'Music', icon: Music, color: 'bg-purple-500', description: 'Regulate emotions with our proprietary algorithm' },
  // { name: 'Resource', icon: Podcast, color: 'bg-blue-500', description: 'Discover podcasts, books, and articles' },
  // { name: 'Resource Finder', icon: MapPin, color: 'bg-green-500', description: 'Locate support resources nearby' },
  { name: 'Real', icon: UserCheck, color: 'bg-yellow-500', description: 'Learn from real people\'s experiences' },
  { name: 'Crisis', icon: AlertTriangle, color: 'bg-red-500', description: 'Get immediate support during crises' },
  { name: 'Journal', icon: Feather, color: 'bg-indigo-500', description: 'AI-assisted journaling and analysis' },
  // { name: 'Coping', icon: Heart, color: 'bg-pink-500', description: 'Access personalized coping strategies' },
  // { name: 'Treatment Planning', icon: Clipboard, color: 'bg-teal-500', description: 'Develop comprehensive care plans' },
  { name: 'Freestyle', icon: MessageSquare, color: 'bg-orange-500', description: 'Open-ended conversations' },
  { name: 'Voice', icon: Mic, color: 'bg-cyan-500', description: 'Interact using voice commands' },
  // { name: 'Dream Analysis', icon: CloudLightning, color: 'bg-violet-500', description: 'Understand your dreams with AI' },
  { name: 'Meditation', icon: Earth, color: 'bg-emerald-500', description: 'Guided meditations and mindfulness' }
];

const Chatbot = () => {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [currentMode, setCurrentMode] = useState(modes[4]); // Default to Freestyle
  const [isModeMenuOpen, setIsModeMenuOpen] = useState(false);
  const [playlist, setPlaylist] = useState(null); // Store playlist when in Music Mode
  const [inputDisabled, setInputDisabled] = useState(false); // Disable input after generating playlist
  const [showSpotifyLinkPopup, setShowSpotifyLinkPopup] = useState(false); // Popup to link Spotify
  const [needsSpotifyReauth, setNeedsSpotifyReauth] = useState(false); // Track if Spotify needs reauthorization
  const bottomRef = useRef(null);
  const [spotifyLinked, setSpotifyLinked] = useState(false);
  const location = useLocation();
  const [wordCount, setWordCount] = useState(0);
  const [journalEntry, setJournalEntry] = useState('');
  const API_BASE_URL = getApiBaseUrl();
  
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;
  
    const newUserMessage = { user: 'You', message, timestamp: new Date() };
    setChatHistory(prev => [...prev, newUserMessage]);
    setMessage('');
    setIsTyping(true);
  
    try {
      const backendUrl = currentMode.name === 'Music' 
        ? `${API_BASE_URL}/api/em/music_mode/`
        : `${API_BASE_URL}/api/em/chatbot/`;
  
      const response = await authFetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, mode: currentMode.name }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        if (currentMode.name === 'Music') {
          setPlaylist(data);
          setInputDisabled(true);
          setChatHistory(prev => [...prev, { user: 'Bot', message: `Playlist "${data.playlist_name}" generated! You can listen to it on Spotify.`, timestamp: new Date() }]);
        } else {
          setChatHistory(prev => [...prev, { user: 'Bot', message: data.response, timestamp: new Date() }]);
        }
      } else if (response.status === 302) {
        // Handle redirect for Spotify authorization
        window.location.href = data.auth_url;
      } else if (data.error === "Spotify account not linked." || data.error.includes("re-link your Spotify account")) {
        setShowSpotifyLinkPopup(true);
      } else if (data.error.includes("invalid_grant") || data.error.includes("Refresh token revoked")) {
        setNeedsSpotifyReauth(true);
        setShowSpotifyLinkPopup(true);
      } else {
        console.error('Response error:', data.error);
        setChatHistory(prev => [...prev, { user: 'Bot', message: `Error: ${data.error}`, timestamp: new Date() }]);
      }
    } catch (error) {
      console.error('Request failed:', error);
      setChatHistory(prev => [...prev, { user: 'Bot', message: 'Sorry, there was an error processing your request.', timestamp: new Date() }]);
    } finally {
      setIsTyping(false);
    }
  };

  const linkSpotify = async () => {
    try {
      const response = await authFetch(`${API_BASE_URL}/api/em/link_spotify/`, {
        method: 'POST',
        body: JSON.stringify({ context: 'music_mode', reauth: true }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Redirect to Spotify authorization page
        window.location.href = data.auth_url;
      } else {
        console.error('Error linking Spotify:', data.error);
        setChatHistory(prev => [...prev, { user: 'Bot', message: `Error linking Spotify: ${data.error}`, timestamp: new Date() }]);
      }
    } catch (error) {
      console.error('Error linking Spotify:', error);
      setChatHistory(prev => [...prev, { user: 'Bot', message: 'Error linking Spotify. Please try again.', timestamp: new Date() }]);
    }
  };
  

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (code) {
      const exchangeCode = async () => {
        try {
          const response = await authFetch(`${API_BASE_URL}/api/em/spotify_callback/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code, state }),
          });

          const data = await response.json();

          if (response.ok) {
            setShowSpotifyLinkPopup(false);
            setChatHistory(prev => [...prev, { user: 'Bot', message: 'Spotify account linked successfully!', timestamp: new Date() }]);
            setSpotifyLinked(true);
          } else {
            console.error('Error exchanging code:', data.error);
            setChatHistory(prev => [...prev, { user: 'Bot', message: `Error linking Spotify: ${data.error}`, timestamp: new Date() }]);
          }
        } catch (error) {
          console.error('Error exchanging code:', error);
        } finally {
          // Clear the code and state from the URL
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      };
      exchangeCode();
    }
  }, [location]);
  // Function to regenerate playlist (use the same input)
  const regeneratePlaylist = async () => {
    await handleSendMessage(); // Reuse the last input to regenerate playlist
  };

  const handlePlayButtonClick = () => {
    const iframe = document.getElementById('spotify-player');
    const src = iframe.getAttribute('src');
    iframe.setAttribute('src', src + '&autoplay=1');
  };

  // Function to update input after playlist generation
  const updateInput = () => {
    setInputDisabled(false); // Re-enable input field
    setPlaylist(null); // Clear playlist
  };

  // Reset chatbot when switching modes
  const switchMode = (mode) => {
    setCurrentMode(mode);
    setInputDisabled(false);
    setPlaylist(null);
    setChatHistory([]);
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory, isTyping]);

  const handleJournalSubmit = async () => {
    if (wordCount < 750) return;

    setIsTyping(true);

    try {
      // Add the journal entry to the chat history immediately
      setChatHistory(prev => [
        ...prev,
        { user: 'You', message: journalEntry, timestamp: new Date() }
      ]);

      const response = await authFetch(`${API_BASE_URL}/api/em/journal/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ entry: journalEntry }),
      });

      const data = await response.json();

      if (response.ok) {
        setChatHistory(prev => [
          ...prev,
          { user: 'Bot', message: data.response, timestamp: new Date() }
        ]);
        setJournalEntry('');
        setWordCount(0);
      } else {
        console.error('Response error:', data.error);
        setChatHistory(prev => [...prev, { user: 'Bot', message: `Error: ${data.error}`, timestamp: new Date() }]);
      }
    } catch (error) {
      console.error('Request failed:', error);
      setChatHistory(prev => [...prev, { user: 'Bot', message: 'Sorry, there was an error processing your request.', timestamp: new Date() }]);
    } finally {
      setIsTyping(false);
    }
  };

  const updateWordCount = useCallback((text) => {
    const words = text.trim().split(/\s+/);
    setWordCount(words.length);
  }, []);

  useEffect(() => {
    updateWordCount(journalEntry);
  }, [journalEntry, updateWordCount]);

  const handleMeditationGeneration = async () => {
    if (!message.trim()) return;

    const newUserMessage = { user: 'You', message, timestamp: new Date() };
    setChatHistory(prev => [...prev, newUserMessage]);
    setMessage('');
    setIsTyping(true);

    try {
      const response = await authFetch(`${API_BASE_URL}/api/em/generate_meditation/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      });

      const data = await response.json();

      if (response.ok) {
        setChatHistory(prev => [
          ...prev,
          {
            user: 'Bot',
            message: `I've generated a personalized meditation for you. You can listen to it here:`,
            timestamp: new Date(),
            meditation: {
              ...data,
              formattedDuration: formatDuration(data.duration)
            }
          }
        ]);
      } else {
        console.error('Response error:', data.error);
        setChatHistory(prev => [...prev, { user: 'Bot', message: `Error: ${data.error}`, timestamp: new Date() }]);
      }
    } catch (error) {
      console.error('Request failed:', error);
      setChatHistory(prev => [...prev, { user: 'Bot', message: 'Sorry, there was an error generating your meditation.', timestamp: new Date() }]);
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-64px)] bg-background-light dark:bg-background-dark">
      {/* Chat Messages */}
      <div className="flex-grow overflow-y-auto p-4 pt-6 space-y-4">
        <AnimatePresence>
          {chatHistory.map((chat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className={`flex ${chat.user === 'You' ? 'justify-end' : 'justify-start'}`}
            >
              <div className={`max-w-xs md:max-w-md ${
                chat.user === 'You' 
                  ? 'bg-primary-light text-white rounded-l-lg rounded-br-lg' 
                  : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-r-lg rounded-bl-lg'
              } p-3 shadow-md`}>
                <p>{chat.message}</p>
                {chat.meditation && (
                  <div className="mt-2">
                    <audio controls src={chat.meditation.audio_url} className="w-full">
                      Your browser does not support the audio element.
                    </audio>
                   
                  </div>
                )}
                <span className="text-xs opacity-50 mt-1 block">
                  {chat.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </span>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
        {isTyping && (
          <div className="flex justify-start">
            <div className="bg-gray-200 dark:bg-gray-700 rounded-full p-3 shadow-md">
              <motion.div
                className="flex space-x-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ repeat: Infinity, duration: 1 }}
              >
                <div className="w-2 h-2 bg-gray-400 dark:bg-gray-400 rounded-full"></div>
                <div className="w-2 h-2 bg-gray-400 dark:bg-gray-400 rounded-full"></div>
                <div className="w-2 h-2 bg-gray-400 dark:bg-gray-400 rounded-full"></div>
              </motion.div>
            </div>
          </div>
        )}
 {playlist && (
          <div className="text-white p-4 rounded-lg shadow-md mt-4">
            <h3 className="text-lg mb-2">{playlist.playlist_name}</h3>
            {/* Play Button to comply with autoplay policies */}
       
            <iframe
              id="spotify-player"
              src={`https://open.spotify.com/embed/playlist/${playlist.playlist_id}`}
              width="100%"
              height="380"
              frameBorder="0"
              allow="encrypted-media"
              allowtransparency="true"
            />
            <p className="mt-2">Playlist has been added to your Spotify account!</p>

          </div>
        )}

        <div ref={bottomRef} />
      </div>

            {/* Spotify Linking Popup */}
            {showSpotifyLinkPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-bold mb-4">
              {needsSpotifyReauth ? "Re-authorize Spotify" : "Link Your Spotify Account"}
            </h2>
            <p className="mb-4">
              {needsSpotifyReauth 
                ? "Your Spotify authorization has expired. Please re-authorize to continue using Music Mode."
                : "You need to link your Spotify account to generate music playlists."}
            </p>
            <button 
              onClick={linkSpotify}
              className="bg-primary-light text-white px-4 py-2 rounded-full"
            >
              {needsSpotifyReauth ? "Re-authorize Spotify" : "Link Spotify"}
            </button>
          </div>
        </div>
      )}

      {/* Input Area */}
      <div className="bg-background-light dark:bg-background-dark p-4 border-t dark:border-gray-700">
        <div className="flex items-center space-x-2 mb-2">
          <div className="relative">
            <button
              onClick={() => setIsModeMenuOpen(!isModeMenuOpen)}
              className="flex items-center space-x-2 bg-primary-light dark:bg-primary-dark text-white px-3 py-1 rounded-full text-sm font-medium"
            >
              <currentMode.icon size={16} />
              <span>{currentMode.name}</span>
              <ChevronDown size={16} />
            </button>
            <AnimatePresence>
              {isModeMenuOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute left-0 bottom-full mb-2 w-64 bg-white dark:bg-gray-700 rounded-md shadow-lg z-10 max-h-80 overflow-y-auto"
                >
                  {modes.map((mode) => (
                    <button
                      key={mode.name}
                      onClick={() => {
                        switchMode(mode);  // Reset chat and input on mode switch
                        setIsModeMenuOpen(false);
                      }}
                      className="flex items-start space-x-2 w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                    >
                      <mode.icon size={16} className={`${mode.color} text-white p-1 rounded-full mt-1 flex-shrink-0`} />
                      <div className="flex flex-col items-start">
                        <span className="font-medium">{mode.name}</span>
                        <span className="text-xs text-gray-500 dark:text-gray-400 text-left">{mode.description}</span>
                      </div>
                    </button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        {currentMode.name === 'Journal' ? (
          <div className="flex flex-col space-y-2">
            <textarea
              value={journalEntry}
              onChange={(e) => setJournalEntry(e.target.value)}
              placeholder="Write your journal entry here (750 words minimum)..."
              className="w-full h-40 p-2 bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-lg outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark"
            />
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Word count: {wordCount} / 750
              </span>
              <button
                onClick={handleJournalSubmit}
                disabled={wordCount < 750}
                className={`bg-primary-light dark:bg-primary-dark text-white px-4 py-2 rounded-full ${
                  wordCount < 750 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-90'
                }`}
              >
                Submit Journal Entry
              </button>
            </div>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && (currentMode.name === 'Meditation' ? handleMeditationGeneration() : handleSendMessage())}
              placeholder={currentMode.name === 'Meditation' ? "describe how you're feeling..." : "type your message..."}
              className="flex-1 bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-full py-2 px-4 outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark"
            />
            <button className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300">
              <Smile size={20} />
            </button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={currentMode.name === 'Meditation' ? handleMeditationGeneration : handleSendMessage}
              className="bg-primary-light dark:bg-primary-dark text-white rounded-full p-2 hover:bg-opacity-90"
            >
              <Send size={20} />
            </motion.button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chatbot;