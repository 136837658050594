import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

const emotions = {
  fear: {
    color: '#8B00FF',
    secondary: ['embarrassed', 'unwanted', 'inferior', 'insecure', 'anxious', 'scared'],
    tertiary: ['tender', 'blushing', 'unsteady', 'cold', 'tense', 'frozen', 'quiet', 'racing heart', 'foot-tapping', 'fidgety', 'numb hands', 'trembling']
  },
  surprise: {
    color: '#FFA500',
    secondary: ['shock', 'confusion', 'awe', 'excitement'],
    tertiary: ['jumpy', 'sweaty palms', 'breathless', 'speechless', 'jaw drop', 'eyebrows up', 'electrified', 'jumpy']
  },
  happy: {
    color: '#FFFF00',
    secondary: ['joy', 'curious', 'proud', 'satisfied', 'courageous', 'peaceful', 'intimate', 'optimistic'],
    tertiary: ['open', 'energetic', 'awake', 'brown-furrow', 'inflated', 'tall', 'soft', 'calm', 'jaw set', 'steady', 'relaxed', 'still', 'sensitive', 'warm', 'light', 'buzzing']
  },
  sad: {
    color: '#0000FF',
    secondary: ['guilt', 'abandoned', 'despair', 'depressed', 'lonely', 'apathetic'],
    tertiary: ['looking down', 'empty', 'curling up', 'slouching', 'crying', 'body aches', 'tiredness', 'hollow feeling', 'slow heart', 'heaviness', 'weak', 'eye rolls']
  },
  disgust: {
    color: '#008000',
    secondary: ['disapproval', 'disappointed', 'awful', 'aversion'],
    tertiary: ['shuddering', 'writhing', 'need to move', 'face-scrunched', 'nausea', 'lump in throat', 'queasy', 'turn away']
  },
  anger: {
    color: '#FF0000',
    secondary: ['hurt', 'insecure', 'hateful', 'mad', 'aggressive', 'irritate', 'distant', 'critical'],
    tertiary: ['lip-tremble', 'limp', 'hiding', 'hot', 'scowl', 'turning away', 'loud words', 'flushed', 'heart racing', 'clenching', 'tight jaw', 'headache', 'numb', 'gut-turning', 'feeling hot', 'lip curled']
  }
};

const EmotionWheel = ({ onSubmit, initialData }) => {
    const [selectedPrimary, setSelectedPrimary] = useState(null);
    const [selectedSecondary, setSelectedSecondary] = useState(null);
    const [selectedTertiary, setSelectedTertiary] = useState(null);
    const [intensity, setIntensity] = useState(1);
    const [isIntensityFocused, setIsIntensityFocused] = useState(false);
    const [wheelSize, setWheelSize] = useState(600);
    const inputRef = useRef(null);
    const containerRef = useRef(null);
  
    useEffect(() => {
      const updateWheelSize = () => {
        if (containerRef.current) {
          const containerWidth = containerRef.current.offsetWidth;
          const newSize = Math.min(containerWidth - 40, 600); // 40px for padding
          setWheelSize(newSize);
        }
      };
  
      updateWheelSize();
      window.addEventListener('resize', updateWheelSize);
      return () => window.removeEventListener('resize', updateWheelSize);
    }, []);
  
    const centerX = wheelSize / 2;
    const centerY = wheelSize / 2;
    const primaryRadius = wheelSize * 0.16;
    const secondaryRadius = wheelSize * 0.3;
    const tertiaryRadius = wheelSize * 0.43;
    const intensityRadius = wheelSize * 0.08;

  const createWedge = (startAngle, endAngle, innerRadius, outerRadius) => {
    const innerStart = polarToCartesian(centerX, centerY, innerRadius, endAngle);
    const innerEnd = polarToCartesian(centerX, centerY, innerRadius, startAngle);
    const outerStart = polarToCartesian(centerX, centerY, outerRadius, endAngle);
    const outerEnd = polarToCartesian(centerX, centerY, outerRadius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    return [
      "M", innerStart.x, innerStart.y,
      "A", innerRadius, innerRadius, 0, largeArcFlag, 0, innerEnd.x, innerEnd.y,
      "L", outerEnd.x, outerEnd.y,
      "A", outerRadius, outerRadius, 0, largeArcFlag, 1, outerStart.x, outerStart.y,
      "Z"
    ].join(" ");
  };

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  };

  const getFontSize = (text, maxLength, baseSize) => {
    const size = baseSize * (maxLength / Math.max(text.length, maxLength / 2));
    return Math.min(Math.max(size, 6), baseSize); // Clamp between 6 and baseSize
  };

  const handleTertiaryClick = (primary, tertiary) => {
    if (selectedPrimary === primary && selectedTertiary === tertiary) {
      setSelectedPrimary(null);
      setSelectedTertiary(null);
      setSelectedSecondary(null);
      setIntensity(1);
    } else {
      setSelectedPrimary(primary);
      setSelectedTertiary(tertiary);
      setSelectedSecondary(null);
    }
  };

  const handleSecondaryClick = (secondary) => {
    setSelectedSecondary(selectedSecondary === secondary ? null : secondary);
  };

  const handleIntensityChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, '');
    const numValue = parseInt(value, 10);
    if (!isNaN(numValue) && numValue >= 1 && numValue <= 10) {
      setIntensity(numValue);
    } else if (value === '') {
      setIntensity('');
    }
  };

  const handleIntensityFocus = () => {
    setIsIntensityFocused(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleIntensityBlur = () => {
    setIsIntensityFocused(false);
  };

  useEffect(() => {
    if (inputRef.current && isIntensityFocused) {
      inputRef.current.focus();
    }
  }, [isIntensityFocused]);

  useEffect(() => {
    if (initialData) {
      setSelectedPrimary(initialData.primary_emotion);
      setSelectedSecondary(initialData.secondary_emotion);
      setSelectedTertiary(initialData.tertiary_emotion);
      setIntensity(initialData.intensity);
    }
  }, [initialData]);

  const handleSubmit = () => {
    if (selectedPrimary && selectedTertiary && intensity) {
      onSubmit({
        primary_emotion: selectedPrimary,
        secondary_emotion: selectedSecondary || '',
        tertiary_emotion: selectedTertiary,
        intensity: intensity,
      });
    }
  };

  return (
    <div ref={containerRef} className="w-full max-w-2xl mx-auto select-none bg-background-light dark:bg-background-dark p-4 rounded-xl shadow-pop-light dark:shadow-pop-dark">
      <h2 className="text-2xl md:text-3xl font-bold mb-4 text-primary-light dark:text-primary-dark text-center">Emotion Wheel</h2>
      <svg width={wheelSize} height={wheelSize} viewBox={`0 0 ${wheelSize} ${wheelSize}`}>
        <defs>
          <filter id="glow">
            <feGaussianBlur stdDeviation="3.5" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <path id="intensityCircle" d={`M ${centerX - intensityRadius + 10} ${centerY} A ${intensityRadius - 10} ${intensityRadius - 10} 0 0 1 ${centerX + intensityRadius - 10} ${centerY}`} />
        </defs>
        {Object.entries(emotions).map(([primary, data], primaryIndex) => {
          const primaryStartAngle = primaryIndex * 60;
          const primaryEndAngle = (primaryIndex + 1) * 60;
          const isPrimarySelected = selectedPrimary === primary;
          return (
            <g key={primary}>
              {/* Primary emotion (only visible when secondary is selected) */}
              {isPrimarySelected && selectedSecondary && (
                <motion.g
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <path
                    d={createWedge(primaryStartAngle, primaryEndAngle, intensityRadius, primaryRadius)}
                    fill={data.color}
                    stroke="#fff"
                    strokeWidth="2"
                    filter="url(#glow)"
                  />
                  <text
                    fill="#fff"
                    fontSize={getFontSize(primary, 8, wheelSize * 0.025)}
                    fontWeight="bold"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    transform={`
                      rotate(${primaryStartAngle + 30} ${centerX} ${centerY})
                      translate(${centerX} ${centerY - (primaryRadius + intensityRadius) / 2})
                    `}
                  >
                    {primary}
                  </text>
                </motion.g>
              )}

              {/* Secondary emotions (only visible when primary is selected) */}
              {isPrimarySelected && data.secondary.map((secondary, secondaryIndex) => {
                const secondaryStartAngle = primaryStartAngle + (secondaryIndex * (60 / data.secondary.length));
                const secondaryEndAngle = primaryStartAngle + ((secondaryIndex + 1) * (60 / data.secondary.length));
                const midAngle = (secondaryStartAngle + secondaryEndAngle) / 2;
                const midRadius = (primaryRadius + secondaryRadius) / 2;
                const isSecondarySelected = selectedSecondary === secondary;
                return (
                  <g 
                    key={secondary} 
                    onClick={() => handleSecondaryClick(secondary)} 
                    style={{ cursor: 'pointer' }}
                  >
                    <path
                      d={createWedge(secondaryStartAngle, secondaryEndAngle, primaryRadius, secondaryRadius)}
                      fill={data.color}
                      opacity={isSecondarySelected ? 1 : 0.7}
                      stroke="#fff"
                      strokeWidth="1"
                      filter={isSecondarySelected ? "url(#glow)" : "none"}
                    />
                    <text
                      fill="#fff"
                      fontSize={getFontSize(secondary, 12, wheelSize * 0.02)}
                      textAnchor="middle"
                      dominantBaseline="middle"
                      transform={`
                        rotate(${midAngle} ${centerX} ${centerY})
                        translate(${centerX} ${centerY - midRadius})
                        rotate(90)
                      `}
                    >
                      {secondary}
                    </text>
                  </g>
                );
              })}

              {/* Tertiary emotions (always visible) */}
              {data.tertiary.map((tertiary, tertiaryIndex) => {
                const tertiaryStartAngle = primaryStartAngle + (tertiaryIndex * (60 / data.tertiary.length));
                const tertiaryEndAngle = primaryStartAngle + ((tertiaryIndex + 1) * (60 / data.tertiary.length));
                const midAngle = (tertiaryStartAngle + tertiaryEndAngle) / 2;
                const midRadius = (secondaryRadius + tertiaryRadius) / 2;
                const isTertiarySelected = isPrimarySelected && selectedTertiary === tertiary;
                return (
                  <g 
                    key={tertiary} 
                    onClick={() => handleTertiaryClick(primary, tertiary)} 
                    style={{ cursor: 'pointer' }}
                  >
                    <path
                      d={createWedge(tertiaryStartAngle, tertiaryEndAngle, secondaryRadius, tertiaryRadius)}
                      fill={data.color}
                      opacity={isTertiarySelected ? 1 : 0.5}
                      stroke="#fff"
                      strokeWidth="1"
                      filter={isTertiarySelected ? "url(#glow)" : "none"}
                    />
                    <text
                      fill="#fff"
                      fontSize={getFontSize(tertiary, 16, wheelSize * 0.016)}
                      textAnchor="middle"
                      dominantBaseline="middle"
                      transform={`
                        rotate(${midAngle} ${centerX} ${centerY})
                        translate(${centerX} ${centerY - midRadius})
                        rotate(90)
                      `}
                    >
                      {tertiary}
                    </text>
                  </g>
                );
              })}
            </g>
          );
        })}

        {/* Intensity input */}
        <g 
          onClick={handleIntensityFocus} 
          style={{ cursor: 'pointer' }}
        >
          <circle
            cx={centerX}
            cy={centerY}
            r={intensityRadius}
            fill={selectedPrimary ? emotions[selectedPrimary].color : "gray"}
            stroke="#fff"
            strokeWidth="2"
          />
          {/* <text fontSize={wheelSize * 0.02} fill="#fff" fontWeight="bold" dy="8">
            <textPath href="#intensityCircle" startOffset="50%" textAnchor="middle" letterSpacing="0.3em">
              intensity
            </textPath>
          </text> */}
          <foreignObject
            x={centerX - intensityRadius / 2}
            y={centerY - intensityRadius / 2}
            width={intensityRadius}
            height={intensityRadius}
          >
            <div xmlns="http://www.w3.org/1999/xhtml" className="h-full w-full flex flex-col items-center justify-center">
              <input
                ref={inputRef}
                type="text"
                inputMode="numeric"
                pattern="[1-9]|10"
                value={intensity}
                onChange={handleIntensityChange}
                onBlur={handleIntensityBlur}
                disabled={!isIntensityFocused}
                className="w-full h-full text-center bg-transparent border-none outline-none text-lg font-bold"
                style={{
                  color: "#fff",
                  WebkitAppearance: 'none',
                  MozAppearance: 'textfield',
                  pointerEvents: isIntensityFocused ? 'auto' : 'none'
                }}
              />
            </div>
          </foreignObject>
        </g>
      </svg>
      <div className="mt-4 flex justify-center">
        <button
          onClick={handleSubmit}
          className="px-6 py-2 bg-primary-light dark:bg-primary-dark text-white rounded-full font-semibold"
          disabled={!selectedPrimary || !selectedTertiary || !intensity}
        >
          Submit Mood
        </button>
      </div>
    </div>
  );
};

export default EmotionWheel;