import { refreshIdToken, clearTokens } from './tokenUtils';
import { showSessionExpiredPopup } from './sessionUtils';

export const authFetch = async (url, options = {}) => {
  let id_token = localStorage.getItem('id_token');
  if (!id_token) {
    throw new Error('User is not authenticated');
  }

  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${id_token}`,
      },
    });

    if (response.status === 401 || response.status === 403) {
      try {
        id_token = await refreshIdToken();
        
        return fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${id_token}`,
          },
        });
      } catch (refreshError) {
        clearTokens();
        showSessionExpiredPopup();
        throw new Error('Session expired');
      }
    }

    return response;
  } catch (error) {
    console.error('Error in authFetch:', error);
    throw error;
  }
};