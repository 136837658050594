import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import { Tooltip } from 'react-tooltip';

const geoUrl = "/data/countries-110m.json";

const WorldMap = ({ globalTrends }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    console.log("Global Trends Data:", globalTrends);
  }, [globalTrends]);

  const getEmotionColor = (emotion) => {
    const emotionColors = {
      happy: '#00FFFF',
      sad: '#0080FF',
      anger: '#FF3300',
      fear: '#FF00FF',
      surprise: '#FFFF00',
      disgust: '#008000',
    };
    return emotionColors[emotion] || '#FFFFFF';
  };

  const getMaxEmotionCount = () => {
    return Math.max(...globalTrends.flatMap(trend => 
      trend.emotions.map(e => e.count)
    )); 
  };

  const colorScale = scaleLinear()
    .domain([0, getMaxEmotionCount()])
    .range(["#001F3F", "#00FFFF"]);

  const handleMarkerClick = (marker) => {
    setSelectedLocation(marker);
  };

  return (
    <div className="relative w-full h-full bg-black overflow-hidden">
      <div className="absolute inset-0 bg-grid-pattern"></div>
      
      <ComposableMap
        projection="geoEqualEarth"
        projectionConfig={{
          scale: 200,
        }}
        className="w-full h-full"
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="transparent"
                stroke={'#00FFFF'}
                strokeWidth={0.2}
              />
            ))
          }
        </Geographies>
        {globalTrends && globalTrends.map((trend, index) => {
          console.log("Plotting trend:", trend);
          if (!trend.coordinates) {
            console.warn("Missing coordinates for trend:", trend);
            return null;
          }
          return (
            <Marker key={index} coordinates={trend.coordinates}>
              <motion.circle
                r={4}
                fill={getEmotionColor(trend.emotions[0].emotion)}
                strokeWidth={2}
                stroke={getEmotionColor(trend.emotions[0].emotion)}
                strokeOpacity={0.3}
                animate={{
                  scale: [1, 1.5, 1],
                  strokeOpacity: [0.3, 0.6, 0.3],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "loop"
                }}
                onClick={() => handleMarkerClick(trend)}
                data-tooltip-id="marker-tooltip"
                data-tooltip-content={trend.location}
              />
            </Marker>
          );
        })}
      </ComposableMap>
      <Tooltip id="marker-tooltip" />
      {selectedLocation && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="absolute bottom-4 left-4 bg-black bg-opacity-70 p-4 rounded-lg shadow-lg border border-cyan-400"
        >
          <h3 className="text-lg font-semibold mb-2 text-cyan-400">
            {selectedLocation.location}
          </h3>
          <ul className="space-y-1">
            {selectedLocation.emotions.map((emotion, i) => (
              <li key={i} className="flex justify-between items-center">
                <span className="text-gray-300">{emotion.emotion}:</span>
                <span 
                  className="font-semibold px-2 py-1 rounded-full text-black"
                  style={{ backgroundColor: getEmotionColor(emotion.emotion) }}
                >
                  {emotion.count}
                </span>
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default WorldMap;