import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight, Calendar, BarChart2, Activity } from 'lucide-react';

const emotions = [
  'Anger', 'Anxiety/Stress', 'Content', 'Depressed/Sad', 'Disconnected',
  'Distracted', 'Elevated/Energized', 'Happy', 'Hopeful', 'Hurt',
  'Lethargic/No Energy', 'Numb/Unsure', 'Overwhelmed', 'Panic',
  'Paranoid', 'Shame/Guilt'
];

const actions = [
  'Hurt Myself (Self-harm)', 'Kill Myself (suicide)', 'Drink Alcohol', 'Take Drugs'
];

const getIntensityColor = (value) => {
  const intensity = Math.min(Math.max(value / 5, 0), 1);
  return `rgba(255, 107, 107, ${intensity})`;
};

const EmotionCell = ({ emotions }) => (
  <div className="flex flex-wrap gap-1">
    {Object.entries(emotions).map(([emotion, value]) => (
      <div
        key={emotion}
        className="w-3 h-3 rounded-full"
        style={{ backgroundColor: getIntensityColor(value) }}
        title={`${emotion}: ${value}`}
      />
    ))}
  </div>
);

const ActionCell = ({ actions }) => (
  <div className="flex flex-col gap-1">
    {Object.entries(actions).map(([action, value]) => (
      value > 0 && (
        <div
          key={action}
          className="h-1 bg-accent-light dark:bg-accent-dark rounded-full"
          style={{ width: `${(value / 5) * 100}%` }}
          title={`${action}: ${value}`}
        />
      )
    ))}
  </div>
);

const ExpandedRow = ({ data }) => (
  <motion.div
    initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: 'auto' }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ duration: 0.3 }}
    className="bg-tertiary-light dark:bg-tertiary-dark p-4"
  >
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <h4 className="font-bold mb-2 text-text-light dark:text-text-dark">Emotions</h4>
        {Object.entries(data.emotion_ratings).map(([emotion, value]) => (
          <div key={emotion} className="flex items-center mb-1">
            <span className="w-24 text-sm text-text-light dark:text-text-dark">{emotion}</span>
            <div className="flex-grow bg-background-light dark:bg-background-dark rounded-full h-2">
              <div
                className="h-2 rounded-full"
                style={{
                  width: `${(value / 5) * 100}%`,
                  backgroundColor: getIntensityColor(value)
                }}
              />
            </div>
            <span className="ml-2 text-sm text-text-light dark:text-text-dark">{value}</span>
          </div>
        ))}
      </div>
      <div>
        <h4 className="font-bold mb-2 text-text-light dark:text-text-dark">Actions</h4>
        {Object.entries(data.action_ratings).map(([action, value]) => (
          <div key={action} className="flex items-center mb-1">
            <span className="w-32 text-sm text-text-light dark:text-text-dark">{action}</span>
            <div className="flex-grow bg-background-light dark:bg-background-dark rounded-full h-2">
              <div
                className="h-2 rounded-full bg-accent-light dark:bg-accent-dark"
                style={{ width: `${(value / 5) * 100}%` }}
              />
            </div>
            <span className="ml-2 text-sm text-text-light dark:text-text-dark">{value}</span>
          </div>
        ))}
        <h4 className="font-bold mt-4 mb-2 text-text-light dark:text-text-dark">Thoughts</h4>
        <ul className="list-disc list-inside">
          {data.thoughts.map((thought, index) => (
            <li key={index} className="text-sm text-text-light dark:text-text-dark">{thought}</li>
          ))}
        </ul>
      </div>
    </div>
  </motion.div>
);

const DiaryOverview = ({ data }) => {
    const [viewMode, setViewMode] = useState('week');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [expandedRows, setExpandedRows] = useState({});
    const [expandedMonths, setExpandedMonths] = useState({});
    const [filteredData, setFilteredData] = useState([]);
  
    useEffect(() => {
      filterData();
    }, [data, currentDate, viewMode]);
  
    const getWeekRange = (date) => {
      const start = new Date(date);
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1)); // Start on Monday
      const end = new Date(start);
      end.setDate(end.getDate() + 6);
      return { start, end };
    };
  
    const filterData = () => {
      if (!data) return;
  
      let startDate, endDate;
      switch (viewMode) {
        case 'year':
          startDate = new Date(currentDate.getFullYear(), 0, 1);
          endDate = new Date(currentDate.getFullYear() + 1, 0, 1);
          break;
        case 'month':
          startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
          break;
        case 'week':
          ({ start: startDate, end: endDate } = getWeekRange(currentDate));
          break;
        case 'day':
          startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
          endDate = new Date(startDate);
          endDate.setDate(endDate.getDate() + 1);
          break;
      }
  
      const filtered = data.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= startDate && itemDate < endDate;
      });
  
      setFilteredData(filtered);
    };
  
    const navigateDate = (direction) => {
      setCurrentDate(prev => {
        const newDate = new Date(prev);
        switch (viewMode) {
          case 'year':
            newDate.setFullYear(newDate.getFullYear() + direction);
            break;
          case 'month':
            newDate.setMonth(newDate.getMonth() + direction);
            break;
          case 'week':
            newDate.setDate(newDate.getDate() + direction * 7);
            break;
          case 'day':
            newDate.setDate(newDate.getDate() + direction);
            break;
        }
        return newDate;
      });
    };
  
    const toggleRowExpansion = (index) => {
      setExpandedRows(prev => ({ ...prev, [index]: !prev[index] }));
    };
  
    const toggleMonthExpansion = (month) => {
      setExpandedMonths(prev => ({ ...prev, [month]: !prev[month] }));
    };

    const renderDayView = () => {
        return (
          <div className="space-y-4">
            {filteredData.length > 0 ? (
              filteredData.map((entry, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="bg-secondary-light dark:bg-secondary-dark p-6 rounded-lg shadow-md"
                >
                  <h3 className="text-xl font-semibold text-primary-light dark:text-primary-dark mb-4">
                    {new Date(entry.date).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <h4 className="font-bold mb-3 text-text-light dark:text-text-dark flex items-center">
                        <Activity className="mr-2" size={18} />
                        Emotions
                      </h4>
                      {Object.entries(entry.emotion_ratings).map(([emotion, value]) => (
                        <div key={emotion} className="flex items-center mb-2">
                          <span className="w-24 text-sm text-text-light dark:text-text-dark">{emotion}</span>
                          <div className="flex-grow bg-background-light dark:bg-background-dark rounded-full h-2">
                            <motion.div
                              className="h-2 rounded-full"
                              style={{ backgroundColor: getIntensityColor(value) }}
                              initial={{ width: 0 }}
                              animate={{ width: `${(value / 5) * 100}%` }}
                              transition={{ duration: 0.5 }}
                            />
                          </div>
                          <span className="ml-2 text-sm text-text-light dark:text-text-dark">{value}</span>
                        </div>
                      ))}
                    </div>
                    <div>
                      <h4 className="font-bold mb-3 text-text-light dark:text-text-dark flex items-center">
                        <BarChart2 className="mr-2" size={18} />
                        Actions
                      </h4>
                      {Object.entries(entry.action_ratings).map(([action, value]) => (
                        <div key={action} className="flex items-center mb-2">
                          <span className="w-32 text-sm text-text-light dark:text-text-dark">{action}</span>
                          <div className="flex-grow bg-background-light dark:bg-background-dark rounded-full h-2">
                            <motion.div
                              className="h-2 rounded-full bg-accent-light dark:bg-accent-dark"
                              initial={{ width: 0 }}
                              animate={{ width: `${(value / 5) * 100}%` }}
                              transition={{ duration: 0.5 }}
                            />
                          </div>
                          <span className="ml-2 text-sm text-text-light dark:text-text-dark">{value}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <h4 className="font-bold mt-4 mb-2 text-text-light dark:text-text-dark flex items-center">
                    <Calendar className="mr-2" size={18} />
                    Thoughts
                  </h4>
                  <ul className="list-disc list-inside space-y-1">
                    {entry.thoughts.map((thought, thoughtIndex) => (
                      <li key={thoughtIndex} className="text-sm text-text-light dark:text-text-dark">{thought}</li>
                    ))}
                  </ul>
                </motion.div>
              ))
            ) : (
              <div className="text-center text-text-light dark:text-text-dark p-6 bg-secondary-light dark:bg-secondary-dark rounded-lg">
                No entries for this day.
              </div>
            )}
          </div>
        );
      };
  
    const renderYearView = () => {
      const monthlyData = filteredData.reduce((acc, entry) => {
        const month = new Date(entry.date).getMonth();
        if (!acc[month]) {
          acc[month] = [];
        }
        acc[month].push(entry);
        return acc;
      }, {});
  
      return (
        <div className="space-y-4">
          {Object.entries(monthlyData).map(([month, entries]) => (
            <motion.div
              key={month}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="bg-secondary-light dark:bg-secondary-dark rounded-lg overflow-hidden shadow-md"
            >
              <motion.div 
                className="flex justify-between items-center p-4 cursor-pointer"
                onClick={() => toggleMonthExpansion(month)}
                whileHover={{ backgroundColor: 'rgba(0,0,0,0.05)' }}
              >
                <span className="text-text-light dark:text-text-dark font-bold">
                  {new Date(2024, month).toLocaleString('default', { month: 'long' })}
                </span>
                <motion.button
                  className="p-1 rounded-full bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  {expandedMonths[month] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                </motion.button>
              </motion.div>
              <AnimatePresence>
                {expandedMonths[month] && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <table className="w-full">
                      <thead>
                        <tr className="bg-tertiary-light dark:bg-tertiary-dark">
                          <th className="p-3 text-left text-text-light dark:text-text-dark">Date</th>
                          <th className="p-3 text-left text-text-light dark:text-text-dark">Emotions</th>
                          <th className="p-3 text-left text-text-light dark:text-text-dark">Actions</th>
                          <th className="p-3 text-left text-text-light dark:text-text-dark">Expand</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entries.map((entry, index) => (
                          <React.Fragment key={index}>
                            <tr className="border-b border-tertiary-light dark:border-tertiary-dark">
                              <td className="p-3 text-text-light dark:text-text-dark">
                                {new Date(entry.date).toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric' })}
                              </td>
                              <td className="p-3">
                                <EmotionCell emotions={entry.emotion_ratings} />
                              </td>
                              <td className="p-3">
                                <ActionCell actions={entry.action_ratings} />
                              </td>
                              <td className="p-3">
                                <motion.button
                                  onClick={() => toggleRowExpansion(`${month}-${index}`)}
                                  className="p-1 rounded-full bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark"
                                  whileHover={{ scale: 1.1 }}
                                  whileTap={{ scale: 0.9 }}
                                >
                                  {expandedRows[`${month}-${index}`] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                                </motion.button>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4">
                                <AnimatePresence>
                                  {expandedRows[`${month}-${index}`] && <ExpandedRow data={entry} />}
                                </AnimatePresence>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      );
    };
  
    const renderTable = () => {
        if (viewMode === 'year') {
          return renderYearView();
        } else if (viewMode === 'day') {
          return renderDayView();
        } else {
          return (
            <table className="w-full">
              <thead>
                <tr className="bg-secondary-light dark:bg-secondary-dark">
                  <th className="p-3 text-left text-text-light dark:text-text-dark">Date</th>
                  <th className="p-3 text-left text-text-light dark:text-text-dark">Emotions</th>
                  <th className="p-3 text-left text-text-light dark:text-text-dark">Actions</th>
                  <th className="p-3 text-left text-text-light dark:text-text-dark">Expand</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-b border-tertiary-light dark:border-tertiary-dark">
                      <td className="p-3 text-text-light dark:text-text-dark">
                        {new Date(row.date).toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric' })}
                      </td>
                      <td className="p-3">
                        <EmotionCell emotions={row.emotion_ratings} />
                      </td>
                      <td className="p-3">
                        <ActionCell actions={row.action_ratings} />
                      </td>
                      <td className="p-3">
                        <button
                          onClick={() => toggleRowExpansion(index)}
                          className="p-1 rounded-full bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark"
                        >
                          {expandedRows[index] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <AnimatePresence>
                          {expandedRows[index] && <ExpandedRow data={row} />}
                        </AnimatePresence>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          );
        }
      };
    
      return (
        <div className="font-sans bg-background-light dark:bg-background-dark p-6 sm:p-8 rounded-xl shadow-pop-light dark:shadow-pop-dark">
          <div className="max-w-6xl mx-auto">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-6 space-y-4 sm:space-y-0">
              <h1 className="text-3xl font-bold text-primary-light dark:text-primary-dark">Diary Overview</h1>
              <div className="flex items-center space-x-4">
                <motion.button
                  onClick={() => navigateDate(-1)}
                  className="p-2 rounded-full bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <ChevronLeft size={24} />
                </motion.button>
                <span className="text-text-light dark:text-text-dark font-medium">
                  {viewMode === 'year' 
                    ? currentDate.getFullYear()
                    : currentDate.toLocaleDateString(undefined, { 
                        month: 'long', 
                        year: 'numeric',
                        ...(viewMode === 'day' && { day: 'numeric' }),
                        ...(viewMode === 'week' && { day: 'numeric' })
                      })
                  }
                  {viewMode === 'week' && ` (Week ${Math.ceil((currentDate.getDate() + currentDate.getDay()) / 7)})`}
                </span>
                <motion.button
                  onClick={() => navigateDate(1)}
                  className="p-2 rounded-full bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <ChevronRight size={24} />
                </motion.button>
              </div>
            </div>
            <div className="flex flex-wrap justify-center sm:justify-start space-x-2 space-y-2 sm:space-y-0 mb-6">
              {['day', 'week', 'month', 'year'].map((mode) => (
                <motion.button
                  key={mode}
                  onClick={() => setViewMode(mode)}
                  className={`px-4 py-2 rounded-full ${
                    viewMode === mode
                      ? 'bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark'
                      : 'bg-tertiary-light dark:bg-tertiary-dark text-text-light dark:text-text-dark'
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {mode.charAt(0).toUpperCase() + mode.slice(1)}
                </motion.button>
              ))}
            </div>
            <motion.div
              className="bg-secondary-light dark:bg-secondary-dark rounded-lg shadow-lg overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {renderTable()}
            </motion.div>
          </div>
        </div>
      );
    };
    
    export default DiaryOverview;