const ArchiiLogo = () => (
  <div className="relative -mt-4">
    <svg width="160" height="70" viewBox="0 0 160 70" xmlns="http://www.w3.org/2000/svg">
      {/* Neon Glow Definition */}
      <defs>
        <linearGradient id="neonGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#20247b" stopOpacity="1" />
          <stop offset="50%" stopColor="#895b84" stopOpacity="1" />
          <stop offset="100%" stopColor="#ff8c8c" stopOpacity="1" />
        </linearGradient>
        <filter id="neonGlow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" result="blur" />
          <feMerge>
            <feMergeNode in="blur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
  
      {/* Arch positioned above the 'a' */}
      <path
        d="M25,42 Q55,18 85,42"
        stroke="url(#neonGradient)"
        strokeWidth="3"
        fill="none"
        filter="url(#neonGlow)"
      />
      
      {/* Text positioned below the arch */}
      <text
        x="85"
        y="60"
        textAnchor="middle"
        className="text-3xl bg-clip-text text-transparent bg-gradient-to-r from-primary-light to-accent-light dark:from-primary-dark dark:to-accent-dark font-bold"
        style={{
          fontWeight: "bold",
          fill: "url(#neonGradient)"
        }}
      >
        archii
      </text>
    </svg>
  </div>
);

export default ArchiiLogo;