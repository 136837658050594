import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Globe, RefreshCw } from 'lucide-react';
import { authFetch } from '../utils/authFetch';
import WorldMap from '../components/WorldMap';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

const GlobalDashboard = () => {
    const [globalTrends, setGlobalTrends] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const fetchGlobalTrends = async () => {
      setLoading(true);
      try {
        const response = await authFetch(`${getApiBaseUrl()}/api/global/get-global-trends/`);
        if (response.ok) {
          const data = await response.json();
          console.log("Fetched data:", data);
          // Ensure each trend has coordinates
          const processedData = data.map(trend => {
            if (!trend.coordinates) {
              console.warn("Missing coordinates for trend:", trend);
              // You might want to add default coordinates or skip this trend
              return { ...trend, coordinates: [0, 0] };
            }
            return trend;
          });
          setGlobalTrends(processedData);
        } else {
          throw new Error('Failed to fetch global trends');
        }
      } catch (error) {
        console.error('Error fetching global trends:', error);
        setError('Failed to load global trends. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchGlobalTrends();
    }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-black">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
        >
          <Globe size={48} className="text-cyan-400" />
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-black text-red-500">
        <p className="mb-4">{error}</p>
        <button
          onClick={fetchGlobalTrends}
          className="flex items-center bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
        >
          <RefreshCw size={16} className="mr-2" />
          Retry
        </button>
      </div>
    );
  }

  const emotionColors = {
    happy: '#00FFFF',
    sad: '#0080FF',
    angry: '#FF3300',
    fear: '#FF00FF',
    surprise: '#FFFF00',
    disgust: '#008000',
  };

  return (
    <div className="h-screen flex flex-col bg-black text-cyan-400">
      <header className="p-4 border-b border-cyan-400 bg-black bg-opacity-80">
        <p className="text-4xl font-bold text-center">global trends</p>
      </header>
      <main className="flex-grow relative">
        <WorldMap globalTrends={globalTrends} />
      </main>
      <footer className="p-4 border-t border-cyan-400 bg-black bg-opacity-80">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <p className="mb-2 sm:mb-0">Last updated: {new Date().toLocaleTimeString()}</p>
          <div className="flex flex-wrap justify-center sm:justify-start mb-2 sm:mb-0">
            {Object.entries(emotionColors).map(([emotion, color]) => (
              <div key={emotion} className="flex items-center mr-4 mb-2">
                <div className="w-4 h-4 rounded-full mr-2" style={{ backgroundColor: color }}></div>
                <span className="capitalize text-sm">{emotion}</span>
              </div>
            ))}
          </div>
          <button
            onClick={fetchGlobalTrends}
            className="flex items-center bg-cyan-500 hover:bg-cyan-600 text-black font-bold py-2 px-4 rounded text-sm"
          >
            <RefreshCw size={14} className="mr-2" />
            refresh data
          </button>
        </div>
      </footer>
    </div>
  );
};

export default GlobalDashboard;