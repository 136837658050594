import React, { useState, useEffect, useContext, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { authFetch } from '../utils/authFetch';
import { AuthContext } from '../context/AuthContext';
import { User, Mail, Calendar, Camera, Save, AlertCircle, MapPin } from 'lucide-react';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

const Profile = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [profile, setProfile] = useState({
    name: '',
    pronouns: '',
    birthday: '',
    profile_picture_url: '',
    city: '',
    state: '',
    country: '',
    latitude: null,
    longitude: null,
  });
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [message, setMessage] = useState({ type: '', content: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [geolocationError, setGeolocationError] = useState(null);
  const API_BASE_URL = getApiBaseUrl();
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const fetchProfile = useCallback(async () => {
    if (!isAuthenticated) return;

    setIsLoading(true);
    try {
      const response = await authFetch(`${API_BASE_URL}/api/accounts/profile/current_profile/`);
      if (response.ok) {
        const data = await response.json();
        setProfile({
          name: data.name || '',
          pronouns: data.pronouns || '',
          birthday: data.birthday || '',
          profile_picture_url: data.profile_picture_url || '',
          city: data.city || '',
          state: data.state || '',
          country: data.country || '',
          latitude: data.latitude || null,
          longitude: data.longitude || null,
        });
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch profile:', errorData);
        setMessage({ type: 'error', content: 'Failed to fetch profile. Please try again.' });
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
      setMessage({ type: 'error', content: 'Error fetching profile. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  }, [isAuthenticated, API_BASE_URL]);

  const updateGeolocation = useCallback(async () => {
    if ("geolocation" in navigator) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const updatedProfile = {
          ...profile,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };

        const response = await authFetch(`${API_BASE_URL}/api/accounts/profile/current_profile/`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedProfile),
        });

        if (response.ok) {
          setProfile(updatedProfile);
          setMessage({ type: 'success', content: 'Geolocation updated successfully.' });
        } else {
          throw new Error('Failed to update geolocation');
        }
      } catch (error) {
        console.error('Error updating geolocation:', error);
        setGeolocationError('Failed to update geolocation. Please try again.');
      }
    } else {
      setGeolocationError('Geolocation is not supported by your browser.');
    }
  }, [profile, API_BASE_URL]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const handleProfileUpdate = async () => {
    setIsLoading(true);
    try {
      const sanitizedProfile = {
        ...profile,
        birthday: profile.birthday || null,
      };

      console.log("Sending profile data:", sanitizedProfile);

      const response = await authFetch(`${API_BASE_URL}/api/accounts/profile/current_profile/`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(sanitizedProfile),
      });

      if (response.ok) {
        const data = await response.json();
        setProfile(data);
        setMessage({ type: 'success', content: 'Profile updated successfully.' });
      } else {
        const errorData = await response.json();
        console.error('Profile update error:', errorData);
        setMessage({ type: 'error', content: 'Failed to update profile. Please try again.' });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage({ type: 'error', content: 'Error updating profile. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfilePictureUpload = async () => {
    if (!file) {
      setMessage({ type: 'error', content: 'No file selected.' });
      return;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      setMessage({ type: 'error', content: 'Invalid file type. Please upload a JPEG or PNG image.' });
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await authFetch(`${API_BASE_URL}/api/accounts/profile/upload/`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setProfile((prevProfile) => ({
          ...prevProfile,
          profile_picture_url: data.profile_picture_url,
        }));
        setMessage({ type: 'success', content: 'Profile picture uploaded successfully!' });
      } else {
        const data = await response.json();
        setMessage({ type: 'error', content: data.error || 'Failed to upload profile picture.' });
      }
    } catch (error) {
      setMessage({ type: 'error', content: 'Error uploading profile picture. Please try again.' });
      console.error('Upload error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark p-4 sm:p-8">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md mx-auto"
      >
        <h1 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center text-primary-light dark:text-primary-dark">Your Profile</h1>
        <div className="bg-secondary-light dark:bg-secondary-dark shadow-pop-light dark:shadow-pop-dark rounded-xl p-6 sm:p-8">
          <AnimatePresence>
            {message.content && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className={`mb-4 p-3 rounded-lg flex items-center ${
                  message.type === 'error' ? 'bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-200' : 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-200'
                }`}
              >
                <AlertCircle size={20} className="mr-2 flex-shrink-0" />
                <p>{message.content}</p>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="mb-6 sm:mb-8 text-center">
            <div className="w-24 h-24 sm:w-32 sm:h-32 mx-auto mb-4 rounded-full overflow-hidden bg-tertiary-light dark:bg-tertiary-dark">
              <img
                src={previewUrl || profile.profile_picture_url || 'default-avatar.png'}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <label className="cursor-pointer bg-primary-light dark:bg-primary-dark text-white py-2 px-4 rounded-full hover:bg-opacity-90 transition-colors duration-300 inline-flex items-center text-sm sm:text-base">
              <Camera size={16} className="mr-2" />
              Change Picture
              <input
                type="file"
                onChange={handleFileChange}
                className="hidden"
                accept="image/*"
              />
            </label>
            {file && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleProfilePictureUpload}
                className="mt-2 p-2 bg-accent-light dark:bg-accent-dark text-white rounded-full hover:bg-opacity-90 transition-colors duration-300 text-sm sm:text-base"
                disabled={isLoading}
              >
                {isLoading ? 'Uploading...' : 'Upload Picture'}
              </motion.button>
            )}
          </div>
          <div className="space-y-4 sm:space-y-6">
            <div className="relative">
              <User className="absolute top-1/2 left-3 transform -translate-y-1/2 text-primary-light dark:text-primary-dark" size={20} />
              <input
                type="text"
                name="name"
                value={profile.name}
                onChange={handleInputChange}
                className="w-full p-2 pl-10 bg-tertiary-light dark:bg-tertiary-dark border border-text-light dark:border-text-dark rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark"
                placeholder="Your Name"
              />
            </div>
            <div className="relative">
              <Mail className="absolute top-1/2 left-3 transform -translate-y-1/2 text-primary-light dark:text-primary-dark" size={20} />
              <input
                type="text"
                name="pronouns"
                value={profile.pronouns}
                onChange={handleInputChange}
                className="w-full p-2 pl-10 bg-tertiary-light dark:bg-tertiary-dark border border-text-light dark:border-text-dark rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark"
                placeholder="Your Pronouns"
              />
            </div>
           {/* fix bday icon on mobile */}
            <div className="relative">
              <Calendar className="absolute top-1/2 left-3 transform -translate-y-1/2 text-primary-light dark:text-primary-dark" size={20} />
              <input
                type="date"
                name="birthday"
                value={profile.birthday}
                onChange={handleInputChange}
                className="w-full p-2 pl-10 bg-tertiary-light dark:bg-tertiary-dark border border-text-light dark:border-text-dark rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark"
              />
            </div>
            <div className="relative">
              <MapPin className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" size={20} />
              <input
                type="text"
                name="city"
                value={profile.city}
                readOnly
                className="w-full p-2 pl-10 bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-600 cursor-not-allowed"
                placeholder="City (auto-filled)"
              />
            </div>
            <div className="relative">
              <MapPin className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" size={20} />
              <input
                type="text"
                name="state"
                value={profile.state}
                readOnly
                className="w-full p-2 pl-10 bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-600 cursor-not-allowed"
                placeholder="State (auto-filled)"
              />
            </div>
            <div className="relative">
              <MapPin className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" size={20} />
              <input
                type="text"
                name="country"
                value={profile.country}
                readOnly
                className="w-full p-2 pl-10 bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-600 cursor-not-allowed"
                placeholder="Country (auto-filled)"
              />
            </div>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={updateGeolocation}
              className="w-full p-2 bg-accent-light dark:bg-accent-dark text-white rounded-full hover:bg-opacity-90 transition-colors duration-300 flex items-center justify-center"
              disabled={isLoading}
            >
              <MapPin size={20} className="mr-2" />
              Update Location
            </motion.button>
            {geolocationError && (
              <p className="text-red-500 text-sm">{geolocationError}</p>
            )}
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleProfileUpdate}
              className="w-full p-2 bg-primary-light dark:bg-primary-dark text-white rounded-full hover:bg-opacity-90 transition-colors duration-300 flex items-center justify-center"
              disabled={isLoading}
            >
              <Save size={20} className="mr-2" />
              {isLoading ? 'Updating...' : 'Update Profile'}
            </motion.button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Profile;