import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, Lock, User, UserPlus, AlertCircle, Eye, EyeOff } from 'lucide-react';
import { ThemeContext } from '../context/ThemeContext';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { darkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const API_BASE_URL = getApiBaseUrl();

  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    if (password !== confirmPassword) {
      setMessage("Passwords don't match.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/users/signup/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, name }),
      });
      const data = await response.json();
      if (response.ok) {
        navigate('/login', { state: { message: 'Signup successful! Please log in.' } });
      } else {
        setMessage(data.error || 'Signup failed. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred during signup. Please try again.');
      console.error('Signup error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className={`min-h-screen flex justify-center items-center bg-background-light dark:bg-background-dark transition-colors duration-300 ${darkMode ? 'dark' : ''}`}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md px-4 py-8"
      >
        <form onSubmit={handleSignup} className="bg-secondary-light dark:bg-secondary-dark shadow-pop-light dark:shadow-pop-dark rounded-xl px-8 pt-6 pb-8 mb-4">
          <h1 className="text-3xl font-bold mb-6 text-center text-primary-light dark:text-primary-dark">Sign Up for Archii</h1>
          
          <AnimatePresence>
            {message && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
                className="mb-4 p-3 bg-accent-light dark:bg-accent-dark text-text-light dark:text-text-dark rounded-md flex items-center"
              >
                <AlertCircle size={20} className="mr-2 flex-shrink-0" />
                <p>{message}</p>
              </motion.div>
            )}
          </AnimatePresence>

          {/* <div className="mb-4">
            <label className="block text-text-light dark:text-text-dark text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <div className="relative">
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your full name"
                className="shadow appearance-none border rounded-lg w-full py-2 px-3 pl-10 text-text-light dark:text-text-dark leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark bg-tertiary-light dark:bg-tertiary-dark border-text-light dark:border-text-dark"
                required
              />
              <User size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-text-light dark:text-text-dark opacity-50" />
            </div>
          </div> */}

          <div className="mb-4">
            <label className="block text-text-light dark:text-text-dark text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <div className="relative">
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="shadow appearance-none border rounded-lg w-full py-2 px-3 pl-10 text-text-light dark:text-text-dark leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark bg-tertiary-light dark:bg-tertiary-dark border-text-light dark:border-text-dark"
                required
              />
              <Mail size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-text-light dark:text-text-dark opacity-50" />
            </div>
          </div>
          
          <div className="mb-4">
            <label className="block text-text-light dark:text-text-dark text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <div className="relative">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                className="shadow appearance-none border rounded-lg w-full py-2 px-3 pl-10 pr-10 text-text-light dark:text-text-dark leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark bg-tertiary-light dark:bg-tertiary-dark border-text-light dark:border-text-dark"
                required
              />
              <Lock size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-text-light dark:text-text-dark opacity-50" />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-text-light dark:text-text-dark opacity-50 hover:opacity-75 focus:outline-none"
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-text-light dark:text-text-dark text-sm font-bold mb-2" htmlFor="confirm-password">
              Confirm Password
            </label>
            <div className="relative">
              <input
                id="confirm-password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your password"
                className="shadow appearance-none border rounded-lg w-full py-2 px-3 pl-10 pr-10 text-text-light dark:text-text-dark leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark bg-tertiary-light dark:bg-tertiary-dark border-text-light dark:border-text-dark"
                required
              />
              <Lock size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-text-light dark:text-text-dark opacity-50" />
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-text-light dark:text-text-dark opacity-50 hover:opacity-75 focus:outline-none"
              >
                {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>
          
          <div className="flex items-center justify-center">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className={`bg-primary-light dark:bg-primary-dark text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline flex items-center ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-90'}`}
              disabled={isLoading}
            >
              {isLoading ? (
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : (
                <UserPlus size={20} className="mr-2" />
              )}
              {isLoading ? 'Signing up...' : 'Sign Up'}
            </motion.button>
          </div>
        </form>
        <p className="text-center text-text-light dark:text-text-dark text-sm">
          Already have an account? 
          <Link to="/login" className="text-primary-light dark:text-primary-dark font-bold ml-1 hover:text-opacity-80 transition-colors duration-300">
            Log in
          </Link>
        </p>
      </motion.div>
    </div>
  );
};

export default Signup;