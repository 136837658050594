import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Brain, LineChart, GitMerge, Beaker, Users, Music, 
  AlertTriangle, MessageCircle, Book, Heart, Activity,
  Globe, Mic, Target, Compass, CloudLightning, Earth,
  Laptop, Headphones, PenTool, Feather, User, Clock, MapPin,
  Layers
} from 'lucide-react';
import ArchiiLogo from '../components/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

export const getCommunityStats = async () => {
  try {
    const response = await axios.get(`${getApiBaseUrl()}/api/global/community_stats/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching community stats:', error);
    // Return default values if the API call fails
    return {
      active_users: '??+',
      ai_interactions: '??+'
    };
  }
};

const ArchiiLandingPage = () => {
  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <HeroSection />
      <CrisisSection />
      <SolutionSection />
      <CoreTechnologySection />
      <TherapeuticModesSection />
      <FeatureShowcase />
      <ExperimentalLabSection />
      <ResearchHub />
      <CommunitySection />
      <CTASection />
      <Footer />
    </div>
  );
};

// GradientText component for consistent gradient styling
const GradientText = ({ children }) => (
    <span className="bg-gradient-to-r from-primary-light to-accent-light dark:from-primary-dark dark:to-accent-dark text-transparent bg-clip-text">
      {children}
    </span>
  );

// Header Component
const Header = () => (
  <header className="fixed top-0 w-full z-50 bg-background-light/80 dark:bg-background-dark/80 backdrop-blur-md">
    <nav className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
      <div className="flex items-left text-2xl font-bold">
        <GradientText className="ml-2">archii</GradientText>
      </div>
      <div className="flex space-x-6">
        {['Features', 'Research', 'Community', 'About'].map((item) => (
          <a key={item} href={`#${item.toLowerCase()}`} className="hover:text-primary-light dark:hover:text-primary-dark transition-colors">
            {item}
          </a>
        ))}
        <button className="bg-primary-light dark:bg-primary-dark text-white px-4 py-1 rounded-full">
          Early Access
        </button>
      </div>
    </nav>
  </header>
);

// Hero Section with Chat Interface
const HeroSection = () => {
  const [conversation, setConversation] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const chatboxRef = useRef(null);

  const handleSendMessage = (message) => {
    if (message.trim()) {
      setConversation(prev => [...prev, 
        { type: 'user', text: message },
        { type: 'ai', text: "Hi! I'm Archii, your AI mental health research companion. I'm here to explore innovative approaches to mental wellness with you." }
      ]);
      setInputMessage('');
    }
  };

  return (
    <section className="pt-32 pb-20 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          
          {/* <h2 className="text-6xl font-bold mb-6">
            
            <GradientText>archii</GradientText>
          </h2> */}
          <h3 className="text-3xl mb-6">the ai therapy lab for anyone</h3>
          <p className="text-xl mb-12 max-w-2xl mx-auto opacity-75">
            pioneering evidence-based mental health innovation through continuous experimentation and research
          </p>
        </motion.div>

        <AIChat />
      </div>
      <BrainWaves />
    </section>
  );
};

const AIChat = () => {
  const [conversation, setConversation] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [sessionId] = useState(uuidv4());
  const [messageCount, setMessageCount] = useState(0);
  const [shouldPromptSignup, setShouldPromptSignup] = useState(false);
  const chatboxRef = useRef(null);
  const API_BASE_URL = getApiBaseUrl();
  
  const scrollToBottom = () => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (conversation.length > 0) {
      scrollToBottom();
    }
  }, [conversation]);

  const handleSendMessage = async (message) => {
    if (message.trim()) {
      setConversation(prev => [...prev, { type: 'user', text: message }]);
      setInputMessage('');
      setMessageCount(prev => prev + 1);

      try {
        const response = await axios.post(`${API_BASE_URL}/api/accounts/landing-chat/`, {
          message: message,
          session_id: sessionId,
          message_count: messageCount
        });

        setConversation(prev => [...prev, { type: 'ai', text: response.data.message }]);
        
        if (response.data.should_prompt_signup) {
          setShouldPromptSignup(true);
        }
      } catch (error) {
        console.error('Error sending message:', error);
        setConversation(prev => [...prev, { type: 'ai', text: "i'm sorry, i'm having trouble responding right now. please try again later." }]);
      }
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-tertiary-light dark:bg-tertiary-dark p-6 rounded-3xl shadow-lg">
        <div ref={chatboxRef} className="h-64 overflow-y-auto mb-4 space-y-4">
          <AnimatePresence>
            {conversation.map((message, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className={`p-3 rounded-lg ${
                  message.type === 'user' ? 'bg-primary-light dark:bg-primary-dark text-white ml-auto' : 'bg-secondary-light dark:bg-secondary-dark'
                } max-w-[80%]`}
              >
                {message.text}
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
        <div className="flex mt-4">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="learn about archii"
            className="flex-1 p-3 rounded-l-xl border border-primary-light dark:border-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark bg-secondary-light dark:bg-secondary-dark"
            disabled={shouldPromptSignup}
          />
          <button
            onClick={() => handleSendMessage(inputMessage)}
            className="bg-primary-light dark:bg-primary-dark text-white p-3 rounded-r-xl hover:opacity-90 transition-opacity"
            disabled={shouldPromptSignup}
          >
            send
          </button>
        </div>
        {shouldPromptSignup && (
          <div className="mt-4 text-center">
            <p className="mb-2">You've reached the message limit for guests. Sign up to continue chatting!</p>
            <button
              onClick={() => {/* Implement signup logic */}}
              className="bg-accent-light dark:bg-accent-dark text-white p-3 rounded-xl hover:opacity-90 transition-opacity"
            >
              Sign Up Now
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

// Brain Waves Animation
const BrainWaves = () => (
  <div className="absolute top-0 left-0 right-0 bottom-0 z-[-1] overflow-hidden">
    <svg width="100%" height="100%" viewBox="0 0 1440 800" preserveAspectRatio="none">
      <defs>
        <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#20247b" stopOpacity="0.1" />
          <stop offset="50%" stopColor="#ff6b6b" stopOpacity="0.1" />
          <stop offset="100%" stopColor="#20247b" stopOpacity="0.1" />
        </linearGradient>
      </defs>
      <motion.path
        d="M0,128L48,138.7C96,149,192,171,288,181.3C384,192,480,192,576,181.3C672,171,768,149,864,149.3C960,149,1056,171,1152,186.7C1248,203,1344,213,1392,218.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        fill="url(#wave-gradient)"
        animate={{
          d: [
            "M0,128L48,138.7C96,149,192,171,288,181.3C384,192,480,192,576,181.3C672,171,768,149,864,149.3C960,149,1056,171,1152,186.7C1248,203,1344,213,1392,218.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
            "M0,64L48,85.3C96,107,192,149,288,160C384,171,480,149,576,144C672,139,768,149,864,176C960,203,1056,245,1152,240C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ],
          transition: {
            duration: 10,
            repeat: Infinity,
            repeatType: "reverse"
          }
        }}
      />
    </svg>
  </div>
);

// Crisis Section
const CrisisSection = () => (
  <section className="py-20 px-4 bg-tertiary-light dark:bg-tertiary-dark">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold mb-16 text-center">
        Addressing the <GradientText>Global Crisis</GradientText>
      </h2>
      <div className="max-w-4xl mx-auto grid md:grid-cols-2 gap-12">
        <div className="space-y-6 text-left">
          <h3 className="text-2xl font-semibold">Global Impact</h3>
          <div className="space-y-4">
            <p className="text-xl font-bold">970M+ people affected globally</p>
            <ul className="space-y-2">
              <li>280M with Depression</li>
              <li>301M with Anxiety</li>
              <li>389M with Major mental health disorders</li>
            </ul>
          </div>
          <div className="mt-6">
            <h4 className="text-xl font-semibold mb-3">Economic Impact</h4>
            <ul className="space-y-2">
              <li>$1 trillion annual loss in productivity</li>
              <li>$2.5-8.5 trillion estimated global cost by 2030</li>
            </ul>
          </div>
        </div>
        <div className="space-y-6 text-left">
          <h3 className="text-2xl font-semibold">System Limitations</h3>
          <ul className="space-y-4">
            <li className="flex items-center">
              <AlertTriangle className="mr-2 text-primary-light dark:text-primary-dark" />
              4.7 mental health professionals per 100,000 people globally
            </li>
            <li className="flex items-center">
              <Clock className="mr-2 text-primary-light dark:text-primary-dark" />
              48-day average wait time for first appointment
            </li>
            <li className="flex items-center">
              <MapPin className="mr-2 text-primary-light dark:text-primary-dark" />
              60% of rural US counties lack mental health providers
            </li>
            <li className="flex items-center">
              <AlertTriangle className="mr-2 text-primary-light dark:text-primary-dark" />
              US needs 6,398 additional practitioners to meet current demand
            </li>
            <li className="flex items-center">
              <Clock className="mr-2 text-primary-light dark:text-primary-dark" />
              $100-200 per therapy session without insurance
            </li>
            <li className="flex items-center">
              <MapPin className="mr-2 text-primary-light dark:text-primary-dark" />
              $2,000-5,000 annual out-of-pocket costs for regular treatment
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

// New comprehensive solution section
const SolutionSection = () => (
  <section className="py-20 px-4">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold mb-16 text-center">
        The <GradientText>Experimental Lab</GradientText> Approach
      </h2>
      <div className="max-w-4xl mx-auto grid md:grid-cols-2 gap-12">
        <div className="space-y-6 text-left">
          <h3 className="text-2xl font-semibold">Platform Vision</h3>
          <ul className="space-y-4">
            {[
              "World's first experimental AI therapy sandbox",
              "Research-driven approach to mental health innovation",
              "Continuous integration of emerging technologies",
              "Evidence-based feature development"
            ].map((point, index) => (
              <li key={index} className="flex items-center">
                <div className="w-2 h-2 bg-primary-light dark:bg-primary-dark rounded-full mr-3" />
                {point}
              </li>
            ))}
          </ul>
        </div>
        <div className="space-y-6 text-left">
          <h3 className="text-2xl font-semibold">Our Approach</h3>
          <ul className="space-y-4">
            {[
              "Continuous experimentation and validation",
              "Multi-modal analysis of emotional well-being",
              "Real-time adaptation to user needs",
              "Research-backed feature development"
            ].map((point, index) => (
              <li key={index} className="flex items-center">
                <div className="w-2 h-2 bg-primary-light dark:bg-primary-dark rounded-full mr-3" />
                {point}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </section>
);

// New therapeutic modes section
const TherapeuticModesSection = () => {
  const [activeMode, setActiveMode] = useState(0);
  
  const modes = [
    {
      icon: MessageCircle,
      title: "Freestyle Mode",
      description: "Natural, open-ended therapeutic conversations",
      features: [
        "Context-aware responses",
        "Personality matching",
        "Style adaptation",
        "Memory retention",
        "Natural conversation flow",
        "Resource sharing",
        "Coping strategy suggestions",
        "Progress check-ins",
        "Goal alignment",
        "Real-time emotional support",
        "Crisis detection",
        "Multi-topic exploration"
      ]
    },
    {
      icon: Music,
      title: "Music Mode",
      description: "AI-powered emotional regulation through music",
      features: [
        "Music theory analysis",
        "Valence-based curation",
        "Tempo analysis",
        "Harmonic structure evaluation",
        "Emotional resonance mapping",
        "Spotify integration",
        "Custom playlist generation",
        "Real-time mood response tracking",
        "Musical preference learning",
        "Dynamic playlist adjustment",
        "Mood regulation",
        "Sleep assistance"
      ]
    },
    {
      icon: Book,
      title: "Journal Mode",
      description: "Interactive therapeutic journaling experience",
      features: [
        "Emotion detection in entries",
        "Pattern identification",
        "Progress visualization",
        "Mood tracking",
        "Trigger recognition",
        "Historical trend analysis",
        "Insight generation",
        "Custom templates",
        "Photo and media support",
        "Voice-to-text",
        "Guided reflection prompts",
        "Theme detection"
      ]
    },
    {
      icon: Globe,
      title: "Real World Mode",
      description: "Practical support for real-life situations",
      features: [
        "Exposure therapy guidance",
        "In-vivo exercise tracking",
        "Real-time coping strategies",
        "Location-based support",
        "Progress tracking",
        "Success rate monitoring",
        "Challenge gradation",
        "Safety planning",
        "Resource location",
        "Social anxiety management",
        "Performance anxiety support",
        "Daily task assistance"
      ]
    },
    {
      icon: Brain,
      title: "Meditation Mode",
      description: "Personalized AI-generated meditation experiences",
      features: [
        "Dynamic prompt interpretation",
        "Context-aware generation",
        "User preference learning",
        "Situation-specific adaptation",
        "Voice style selection",
        "Background sound mixing",
        "Breathing pattern integration",
        "Guided visualization",
        "Anxiety management",
        "Sleep assistance",
        "Stress reduction",
        "Trauma-informed options"
      ]
    },
    {
      icon: Mic,
      title: "Voice Mode",
      description: "Voice-based therapeutic interactions",
      features: [
        "Emotional tone detection",
        "Speech pattern analysis",
        "Stress indicators",
        "Voice biomarker analysis",
        "Natural conversation flow",
        "Voice response generation",
        "Multi-language support",
        "Voice journaling",
        "Voice-guided exercises",
        "Breathing exercises",
        "Quick check-ins",
        "Crisis support"
      ]
    }
  ];

  return (
    <section className="py-20 px-4">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold mb-16 text-center">
          Therapeutic <GradientText>Modes</GradientText>
        </h2>
        <div className="flex flex-wrap gap-4 justify-center mb-12">
          {modes.map((mode, index) => (
            <button
              key={index}
              className={`px-6 py-3 rounded-full ${
                activeMode === index
                  ? 'bg-primary-light dark:bg-primary-dark text-white'
                  : 'bg-secondary-light dark:bg-secondary-dark hover:bg-primary-light/10 dark:hover:bg-primary-dark/10'
              }`}
              onClick={() => setActiveMode(index)}
            >
              <div className="flex items-center">
                <mode.icon size={20} className="mr-2" />
                {mode.title}
              </div>
            </button>
          ))}
        </div>
        
        <AnimatePresence mode="wait">
          <motion.div
            key={activeMode}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="bg-secondary-light dark:bg-secondary-dark p-8 rounded-2xl"
          >
            <div className="flex items-center mb-6">
              {React.createElement(modes[activeMode].icon, {
                size: 32,
                className: "text-primary-light dark:text-primary-dark mr-4"
              })}
              <div>
                <h3 className="text-2xl font-semibold">{modes[activeMode].title}</h3>
                <p className="opacity-75">{modes[activeMode].description}</p>
              </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              {modes[activeMode].features.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <div className="w-2 h-2 bg-primary-light dark:bg-primary-dark rounded-full mr-3" />
                  {feature}
                </div>
              ))}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  );
};

// New comprehensive feature showcase
const FeatureShowcase = () => {
  const features = [
    {
      id: "ai-support-groups",
      title: "AI Support Groups",
      icon: Users,
      details: {
        management: [
          "6-12 members per group",
          "Topic-specific matching",
          "Dynamic conversation facilitation",
          "Real-time content moderation",
          "Group chemistry optimization",
          "Progress tracking",
          "Member interaction analysis"
        ]
      }
    },

    {
      id: "crisis-monitoring",
      title: "Crisis Monitoring & Support",
      icon: AlertTriangle,
      details: {
        analysis: [
          "Pattern recognition",
          "Risk level assessment",
          "Behavioral change detection",
          "Trigger identification"
        ],
        response: [
          "Automated safety protocols",
          "Resource recommendations",
          "Emergency contact integration",
          "Crisis resource database",
          "Follow-up monitoring"
        ]
      }
    },
    {
      id: "personalized-treatment",
      title: "Personalized Treatment Planning",
      icon: Target,
      details: {
        planning: [
          "Goal setting and tracking",
          "Progress monitoring",
          "Intervention adjustment",
          "Outcome measurement"
        ],
        customization: [
          "Preferred interaction modes",
          "Communication style matching",
          "Activity recommendations",
          "Schedule optimization"
        ]
      }
    },
   
    {
      id: "voice-interaction",
      title: "multi-modal interaction",
      icon: Mic,
      details: {
        analysis: [
          "Emotional tone detection",
          "Speech pattern analysis",
          "Stress indicators",
          "Mood tracking"
        ],
        features: [
          "Natural conversation flow",
          "Voice response generation",
          "Accent adaptation",
          "Multi-language support"
        ]
      }
    },
    {
      id: "mood-thought-analysis",
      title: "Interactive Mood & Thought Pattern Analysis",
      icon: Brain,
      details: {
        tracking: [
          "Real-time mood logging",
          "Thought pattern recognition",
          "Behavior chain analysis",
          "Trigger identification",
          "Environmental factor correlation"
        ],
        analytics: [
          "Network analysis of thoughts/emotions",
          "Visual pattern mapping",
          "Cyclical behavior recognition",
          "Prediction modeling",
          "Early warning system"
        ],
        interactive: [
          "Custom tracking parameters",
          "User-defined categories",
          "Diary card integration",
          "Progress visualization",
          "Pattern intervention suggestions"
        ]
      }
    },


    {
      id: "integrated-monitoring",
      title: "Integrated Multi-Source Data Monitoring",
      icon: Activity,
      details: {
        platform: [
          "Text conversations",
          "Voice interactions",
          "Group participation",
          "Meditation sessions",
          "Music therapy responses",
          "Journal entries",
          "Real-world activities"
        ],
        userReported: [
          "Mood tracking",
          "Thought patterns",
          "Sleep logs",
          "Activity records",
          "Social interactions",
          "Medication tracking",
          "Stress levels",
          "Energy levels"
        ],
        environmental: [
          "Time of day",
          "Weather conditions",
          "Location context",
          "Social context",
          "Activity type"
        ]
      }
    }
  ];

  return (
    <section className="py-20 px-4 bg-tertiary-light dark:bg-tertiary-dark">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold mb-16 text-center">
          Comprehensive <GradientText>Features</GradientText>
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature) => (
            <FeatureCard key={feature.id} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

// Experimental Lab Section
const ExperimentalLabSection = () => (
  <section className="py-20 px-4 bg-tertiary-light dark:bg-tertiary-dark">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold mb-16 text-center">
        The <GradientText>Experimental Lab</GradientText>
      </h2>
      <div className="grid md:grid-cols-2 gap-12">
        <LabFeature
          title="Real-time Analysis"
          description="Continuous monitoring and adaptation of therapeutic approaches"
          features={[
            "Emotion Pattern Recognition",
            "Behavioral Analysis",
            "Crisis Signal Detection",
            "Intervention Effectiveness"
          ]}
        />
        <LabFeature
          title="Research Integration"
          description="Direct connection between innovation and validation"
          features={[
            "Clinical Studies Integration",
            "Data Collection & Analysis",
            "Outcome Measurement",
            "Research Collaboration"
          ]}
        />
      </div>
    </div>
  </section>
);

const LabFeature = ({ title, description, features }) => (
  <motion.div
    className="bg-secondary-light dark:bg-secondary-dark p-8 rounded-2xl"
    whileHover={{ y: -5 }}
  >
    <h3 className="text-2xl font-semibold mb-4">{title}</h3>
    <p className="opacity-75 mb-6">{description}</p>
    <ul className="space-y-3">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center">
          <div className="w-2 h-2 bg-primary-light dark:bg-primary-dark rounded-full mr-3" />
          {feature}
        </li>
      ))}
    </ul>
  </motion.div>
);

// Research Hub Section
const ResearchHub = () => {
  const researchAreas = [
    {
      icon: Brain,
      title: "Neural Response Patterns",
      description: "Advanced emotion prediction algorithms and pattern recognition",
      focus: [
        "Emotion prediction algorithms",
        "Advanced pattern recognition",
        "Neural network optimization",
        "Response modeling"
      ]
    },
    {
      icon: Activity,
      title: "Crisis Prevention",
      description: "Early warning systems and intervention optimization",
      focus: [
        "Early warning indicators",
        "Crisis prediction models",
        "Intervention protocols",
        "Risk assessment systems"
      ]
    },
    {
      icon: Target,
      title: "Treatment Response",
      description: "Outcome optimization and population pattern studies",
      focus: [
        "Treatment effectiveness studies",
        "Outcome measurement protocols",
        "Population pattern analysis",
        "Intervention efficacy research"
      ]
    }
  ];

  return (
    <section className="py-20 px-4">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold mb-16 text-center">
          Research <GradientText>Hub</GradientText>
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {researchAreas.map((area, index) => (
            <motion.div
              key={index}
              className="bg-secondary-light dark:bg-secondary-dark p-8 rounded-2xl"
              whileHover={{ y: -5 }}
            >
              <area.icon size={40} className="text-primary-light dark:text-primary-dark mb-4" />
              <h3 className="text-xl font-semibold mb-2">{area.title}</h3>
              <p className="opacity-75 mb-4">{area.description}</p>
              <ul className="space-y-3">
                {area.focus.map((item, idx) => (
                  <li key={idx} className="flex items-center">
                    <div className="w-2 h-2 bg-primary-light dark:bg-primary-dark rounded-full mr-3" />
                    {item}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
        
        <div className="mt-16 bg-secondary-light dark:bg-secondary-dark p-8 rounded-2xl">
          <h3 className="text-2xl font-bold mb-6">Current Research Focus</h3>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h4 className="text-xl font-semibold mb-4">Clinical Validation</h4>
              <ul className="space-y-3">
                {[
                  "Treatment effectiveness studies",
                  "Outcome measurement protocols",
                  "Safety and efficacy research",
                  "Comparative analysis"
                ].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <div className="w-2 h-2 bg-primary-light dark:bg-primary-dark rounded-full mr-3" />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="text-xl font-semibold mb-4">Innovation Pipeline</h4>
              <ul className="space-y-3">
                {[
                  "Advanced pattern recognition",
                  "Crisis prevention systems",
                  "Behavioral prediction models",
                  "Integration capabilities"
                ].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <div className="w-2 h-2 bg-primary-light dark:bg-primary-dark rounded-full mr-3" />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// Community Section
const CommunitySection = () => {
  const [stats, setStats] = useState([
    { icon: Users, value: "Loading...", label: "Active Users" },
    { icon: MessageCircle, value: "Loading...", label: "AI Interactions" }
  ]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await getCommunityStats();
        setStats([
          { icon: Users, value: data.active_users, label: "Active Users" },
          { icon: MessageCircle, value: data.ai_interactions, label: "AI Interactions" }
        ]);
      } catch (error) {
        console.error('Failed to fetch community stats:', error);
      }
    };

    fetchStats();
  }, []);

  return (
    <section className="py-20 px-4 bg-tertiary-light dark:bg-tertiary-dark">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold mb-16 text-center">
          Growing <GradientText>Community</GradientText>
        </h2>
        
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {stats.map((stat, index) => (
            <StatCard key={index} {...stat} />
          ))}
        </div>
  
        <div className="grid md:grid-cols-2 gap-12">
          <CommunityFeature
            title="AI Support Groups"
            description="Join specialized groups matched to your unique needs"
            features={[
              "Personalized matching algorithm",
              "Real-time moderation",
              "Progress tracking",
              "Peer support network"
            ]}
          />
          <CommunityFeature
            title="Research Participation"
            description="Contribute to mental health innovation"
            features={[
              "Clinical studies",
              "Feature testing",
              "Feedback integration",
              "Early access programs"
            ]}
          />
        </div>
      </div>
    </section>
  );
};
  
const StatCard = ({ icon: Icon, value, label }) => (
  <motion.div
    className="text-center"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <Icon size={40} className="text-primary-light dark:text-primary-dark mx-auto mb-4" />
    <h3 className="text-3xl font-bold mb-2">{value}</h3>
    <p className="opacity-75">{label}</p>
  </motion.div>
);
  
const CommunityFeature = ({ title, description, features }) => (
  <motion.div
    className="bg-secondary-light dark:bg-secondary-dark p-8 rounded-2xl"
    whileHover={{ y: -5 }}
  >
    <h3 className="text-2xl font-semibold mb-2">{title}</h3>
    <p className="opacity-75 mb-6">{description}</p>
    <ul className="space-y-3">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center">
          <div className="w-2 h-2 bg-primary-light dark:bg-primary-dark rounded-full mr-3" />
          {feature}
        </li>
      ))}
    </ul>
  </motion.div>
);
  
// CTA Section
const CTASection = () => (
  <section className="py-20 px-4 bg-tertiary-light dark:bg-tertiary-dark">
    <div className="max-w-4xl mx-auto text-center">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <h2 className="text-4xl font-bold mb-8">
          Join the Future of <GradientText>Mental Health Innovation</GradientText>
        </h2>
        <p className="text-lg mb-12 opacity-75">
          Be part of our mission to revolutionize mental health support through cutting-edge AI research and innovation
        </p>
        <div className="flex flex-col sm:flex-row justify-center gap-4">
          <motion.button
            className="bg-primary-light dark:bg-primary-dark text-white px-8 py-3 rounded-full text-lg font-semibold"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => window.location.href = '/signup'}
          >
            Get Early Access
          </motion.button>
        </div>
      </motion.div>
    </div>
  </section>
);
  
// Footer Section
const Footer = () => (
  <footer className="bg-secondary-light dark:bg-secondary-dark py-12 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
        <div>
          <h3 className="text-2xl font-semibold mb-4 text-primary-light dark:text-primary-dark">archii</h3>
          <p className="text-text-light dark:text-text-dark opacity-75">Revolutionizing mental health care with AI</p>
        </div>
        <div>
          <h4 className="text-xl font-semibold mb-4">Quick Links</h4>
          <ul className="space-y-2">
            {['Sign Up', 'Login'].map((item) => (
              <li key={item}>
                <a href={`/${item.toLowerCase().replace(' ', '')}`} className="text-text-light dark:text-text-dark opacity-75 hover:opacity-100">
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h4 className="text-xl font-semibold mb-4">Contact</h4>
          <p className="text-text-light dark:text-text-dark opacity-75">welcome@archii.io</p>
          {/* <p className="text-text-light dark:text-text-dark opacity-75">Phone: (555) 123-4567</p> */}
        </div>
        <div>
          <h4 className="text-xl font-semibold mb-4">Follow Us</h4>
          <div className="flex space-x-4">
          {[
            { social: 'Twitter', icon: faTwitter, link: 'https://twitter.com/archiiai' },
            { social: 'Instagram', icon: faInstagram, link: 'https://www.instagram.com/archiiai' },
            { social: 'TikTok', icon: faTiktok, link: 'https://www.tiktok.com/@archiiai' },
          ].map(({ social, icon, link }) => (
            <a key={social} href={link} target="_blank" rel="noopener noreferrer" className="text-text-light dark:text-text-dark opacity-75 hover:opacity-100">
              <FontAwesomeIcon icon={icon} className="text-xl" />
            </a>
          ))}
        </div>
        </div>
      </div>
      <div className="mt-8 pt-8 border-t border-text-light dark:border-text-dark border-opacity-20 text-center">
        <p className="text-text-light dark:text-text-dark opacity-75">&copy; 2024 Archii AI. All rights reserved.</p>
      </div>
    </div>
  </footer>
);
  
// Core Technology Section
const CoreTechnologySection = () => {
  const technologies = [
    {
      title: "Experimental Environment",
      icon: Beaker,
      description: "Our sandbox approach to mental health innovation",
      features: [
        "Rapid feature testing",
        "Evidence-based validation",
        "User feedback integration",
        "Continuous improvement cycle"
      ]
    },
    {
      title: "Custom NLP Engine",
      icon: Brain,
      description: "Advanced natural language processing for mental health",
      features: [
        "Emotion pattern recognition",
        "Context-aware responses",
        "Real-world therapy data training",
        "Crisis signal detection"
      ]
    },
    {
      title: "Multi-modal Analysis",
      icon: Layers,
      description: "Comprehensive processing of multiple data streams",
      features: [
        "Text processing",
        "Voice analysis",
        "Music processing",
        "Behavioral pattern recognition"
      ]
    },
    {
      title: "Integration Framework",
      icon: GitMerge,
      description: "Seamless connection with external tools and services",
      features: [
        "Third-party API support",
        "Research tool integration",
        "Cross-platform data synchronization",
        "Secure data exchange"
      ]
    }
  ];

  return (
    <section className="py-20 px-4">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold mb-16 text-center">
          Powered by <GradientText>Advanced Technology</GradientText>
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          {technologies.map((tech, index) => (
            <motion.div
              key={index}
              className="bg-secondary-light dark:bg-secondary-dark p-8 rounded-2xl"
              whileHover={{ y: -5 }}
            >
              <div className="flex items-center mb-4">
                <tech.icon size={32} className="text-primary-light dark:text-primary-dark mr-4" />
                <h3 className="text-2xl font-semibold">{tech.title}</h3>
              </div>
              <p className="mb-4 opacity-75">{tech.description}</p>
              <ul className="space-y-2">
                {tech.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <div className="w-2 h-2 bg-primary-light dark:bg-primary-dark rounded-full mr-3" />
                    {feature}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Feature Card Component
const FeatureCard = ({ icon: Icon, title, details }) => {
  return (
    <motion.div
      className="bg-secondary-light dark:bg-secondary-dark p-6 rounded-2xl"
      whileHover={{ y: -5 }}
    >
      <div className="flex items-center mb-4">
        <Icon size={32} className="text-primary-light dark:text-primary-dark mr-4" />
        <h3 className="text-xl font-semibold">{title}</h3>
      </div>
      
      {Object.entries(details).map(([category, points]) => (
        <div key={category} className="mt-4">
          <h4 className="text-lg font-medium mb-2 capitalize">
            {category.replace(/([A-Z])/g, ' $1').trim()}
          </h4>
          <ul className="space-y-2">
            {points.map((point, index) => (
              <li key={index} className="flex items-center">
                <div className="w-2 h-2 bg-primary-light dark:bg-primary-dark rounded-full mr-3" />
                {point}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </motion.div>
  );
};

export default ArchiiLandingPage;