import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Smile, Frown, Activity, BookOpen } from 'lucide-react';

const emotions = [
  'Anger', 'Anxiety/Stress', 'Content', 'Depressed/Sad', 'Disconnected',
  'Distracted', 'Elevated/Energized', 'Happy', 'Hopeful', 'Hurt',
  'Lethargic/No Energy', 'Numb/Unsure', 'Overwhelmed', 'Panic',
  'Paranoid', 'Shame/Guilt'
];

const actions = [
  'Hurt Myself (Self-harm)', 'Kill Myself (suicide)', 'Drink Alcohol', 'Take Drugs'
];

const DiaryCard = ({ onSubmit, initialData }) => {
  const [activeSection, setActiveSection] = useState('emotions');
  const [emotionRatings, setEmotionRatings] = useState({});
  const [actionRatings, setActionRatings] = useState({});
  const [thoughts, setThoughts] = useState(['', '', '']);

  useEffect(() => {
    if (initialData) {
      setEmotionRatings(initialData.emotion_ratings);
      setActionRatings(initialData.action_ratings);
      setThoughts(initialData.thoughts);
    }
  }, [initialData]);

  const handleRatingChange = (category, item, value) => {
    if (category === 'emotions') {
      setEmotionRatings(prev => ({ ...prev, [item]: value }));
    } else {
      setActionRatings(prev => ({ ...prev, [item]: value }));
    }
  };

  const handleThoughtChange = (index, value) => {
    setThoughts(prev => {
      const newThoughts = [...prev];
      newThoughts[index] = value;
      return newThoughts;
    });
  };

  const RatingInput = ({ value, onChange, color }) => (
    <div className="flex space-x-2">
      {[0, 1, 2, 3, 4, 5].map((n) => (
        <motion.button
          key={n}
          onClick={() => onChange(n)}
          className={`w-8 h-8 rounded-full flex items-center justify-center font-bold transition-all ${
            n <= value
              ? `${color} text-secondary-light dark:text-secondary-dark`
              : 'bg-tertiary-light dark:bg-tertiary-dark text-text-light dark:text-text-dark'
          }`}
          whileHover={{ scale: 1.1, boxShadow: `0 0 8px ${n <= value ? '#ff6b6b' : '#4a5eff'}` }}
          whileTap={{ scale: 0.9 }}
        >
          {n}
        </motion.button>
      ))}
    </div>
  );

  const SectionButton = ({ name, icon: Icon, isActive, onClick }) => (
    <button
      onClick={onClick}
      className={`flex-1 px-2 py-2 rounded-full text-xs sm:text-sm font-semibold transition-all flex flex-col sm:flex-row items-center justify-center sm:justify-start space-y-1 sm:space-y-0 sm:space-x-2 ${
        isActive
          ? 'bg-primary-light dark:bg-primary-dark text-white'
          : 'bg-tertiary-light dark:bg-tertiary-dark text-text-light dark:text-text-dark'
      }`}
    >
      <Icon size={16} />
      <span>{name}</span>
    </button>
  );

  const handleSubmit = () => {
    onSubmit({
      emotion_ratings: emotionRatings,
      action_ratings: actionRatings,
      thoughts: thoughts,
    });
  };

  return (
    <div className="bg-background-light dark:bg-background-dark p-4 sm:p-6 rounded-xl shadow-pop-light dark:shadow-pop-dark">
      <h2 className="text-2xl sm:text-3xl font-bold text-primary-light dark:text-primary-dark mb-4 sm:mb-6">{new Date().toLocaleDateString()}</h2>
      
      <div className="flex space-x-2 sm:space-x-4 mb-4 sm:mb-6">
        <SectionButton
          name="Emotions"
          icon={Smile}
          isActive={activeSection === 'emotions'}
          onClick={() => setActiveSection('emotions')}
        />
        <SectionButton
          name="Actions"
          icon={Activity}
          isActive={activeSection === 'actions'}
          onClick={() => setActiveSection('actions')}
        />
        <SectionButton
          name="Thoughts"
          icon={BookOpen}
          isActive={activeSection === 'thoughts'}
          onClick={() => setActiveSection('thoughts')}
        />
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeSection}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="overflow-y-auto max-h-[60vh] sm:max-h-[70vh]"
        >
          {activeSection === 'emotions' && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {emotions.map(emotion => (
                <div
                  key={emotion}
                  className="bg-secondary-light dark:bg-secondary-dark p-4 rounded-lg"
                >
                  <h3 className="text-sm font-semibold mb-2 text-text-light dark:text-text-dark">{emotion}</h3>
                  <RatingInput
                    value={emotionRatings[emotion] || 0}
                    onChange={(value) => handleRatingChange('emotions', emotion, value)}
                    color="bg-accent-light dark:bg-accent-dark"
                  />
                </div>
              ))}
            </div>
          )}
          {activeSection === 'actions' && (
            <div className="space-y-4">
              {actions.map(action => (
                <div
                  key={action}
                  className="bg-secondary-light dark:bg-secondary-dark p-4 rounded-lg"
                >
                  <h3 className="text-sm font-semibold mb-2 text-text-light dark:text-text-dark">{action}</h3>
                  <RatingInput
                    value={actionRatings[action] || 0}
                    onChange={(value) => handleRatingChange('actions', action, value)}
                    color="bg-primary-light dark:bg-primary-dark"
                  />
                </div>
              ))}
            </div>
          )}
          {activeSection === 'thoughts' && (
            <div className="space-y-4">
              {[0, 1, 2].map((index) => (
                <div
                  key={index}
                  className="bg-secondary-light dark:bg-secondary-dark p-4 rounded-lg"
                >
                  <h3 className="text-sm font-semibold mb-2 text-text-light dark:text-text-dark">Thought #{index + 1}</h3>
                  <textarea
                    value={thoughts[index]}
                    onChange={(e) => handleThoughtChange(index, e.target.value)}
                    className="w-full p-3 bg-tertiary-light dark:bg-tertiary-dark text-text-light dark:text-text-dark rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark resize-none"
                    placeholder={`Enter thought #${index + 1}`}
                    rows="3"
                  />
                </div>
              ))}
            </div>
          )}
        </motion.div>
      </AnimatePresence>
      <button
        onClick={handleSubmit}
        className="mt-4 w-full sm:w-auto px-6 py-2 bg-primary-light dark:bg-primary-dark text-white rounded-full font-semibold"
      >
        Submit Diary Card
      </button>
    </div>
  );
};

const RatingInput = ({ value, onChange, color }) => (
  <div className="flex space-x-1 sm:space-x-2">
    {[0, 1, 2, 3, 4, 5].map((n) => (
      <button
        key={n}
        onClick={() => onChange(n)}
        className={`w-6 h-6 sm:w-8 sm:h-8 rounded-full flex items-center justify-center text-xs sm:text-sm font-bold transition-all ${
          n <= value
            ? `${color} text-white`
            : 'bg-tertiary-light dark:bg-tertiary-dark text-text-light dark:text-text-dark'
        }`}
      >
        {n}
      </button>
    ))}
  </div>
);

export default DiaryCard;