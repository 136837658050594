import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, Info } from 'lucide-react';

const emotions = [
  'Anger', 'Anxiety/Stress', 'Content', 'Depressed/Sad', 'Disconnected',
  'Distracted', 'Elevated/Energized', 'Happy', 'Hopeful', 'Hurt',
  'Lethargic/No Energy', 'Numb/Unsure', 'Overwhelmed', 'Panic',
  'Paranoid', 'Shame/Guilt'
];

const actions = [
  'Hurt Myself (Self-harm)', 'Kill Myself (suicide)', 'Drink Alcohol', 'Take Drugs'
];

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const WeekDiaryCard = ({ data }) => {
  const [expandedSections, setExpandedSections] = useState({
    emotions: true,
    actions: true,
    thoughts: true
  });

  const getIntensityColor = (value) => {
    const intensity = Math.min(Math.max(value / 5, 0), 1);
    return `rgba(255, 107, 107, ${intensity})`;
  };

  const IntensityDot = ({ value }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div className="relative">
        <motion.div
          className="w-4 h-4 rounded-full cursor-pointer"
          style={{ backgroundColor: getIntensityColor(value) }}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
        <AnimatePresence>
          {isHovered && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="absolute z-10 bg-primary-light dark:bg-primary-dark text-white px-2 py-1 rounded text-xs -top-8 left-1/2 transform -translate-x-1/2"
            >
              Intensity: {value}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const ExpandableSection = ({ title, children, isExpanded, toggleExpand }) => (
    <motion.section
      initial={false}
      animate={isExpanded ? { height: 'auto' } : { height: 48 }}
      transition={{ duration: 0.3 }}
      className="overflow-hidden bg-secondary-light dark:bg-secondary-dark rounded-lg shadow-md mb-6"
    >
      <motion.button
        className="w-full p-4 flex justify-between items-center text-xl font-semibold text-primary-light dark:text-primary-dark"
        onClick={toggleExpand}
      >
        <span>{title}</span>
        {isExpanded ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </motion.button>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="p-4"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.section>
  );

  return (
    <div className="font-sans bg-background-light dark:bg-background-dark p-4 sm:p-8 rounded-xl shadow-pop-light dark:shadow-pop-dark">
      <h2 className="text-2xl sm:text-3xl font-bold text-primary-light dark:text-primary-dark mb-6">
        Weekly Overview
      </h2>

      <ExpandableSection
        title="Emotions Overview"
        isExpanded={expandedSections.emotions}
        toggleExpand={() => setExpandedSections(prev => ({ ...prev, emotions: !prev.emotions }))}
      >
        <div className="overflow-x-auto">
          <div className="grid grid-cols-8 gap-2 min-w-[640px]">
            <div></div>
            {daysOfWeek.map(day => (
              <div key={day} className="text-center font-medium text-sm text-text-light dark:text-text-dark">
                {day}
              </div>
            ))}
            {emotions.map(emotion => (
              <React.Fragment key={emotion}>
                <div className="text-sm font-medium text-text-light dark:text-text-dark truncate">
                  {emotion}
                </div>
                {data.map((dayData, index) => (
                  <div key={`${emotion}-${index}`} className="flex justify-center items-center">
                    <IntensityDot value={dayData.emotion_ratings[emotion] || 0} />
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </ExpandableSection>

      <ExpandableSection
        title="Actions Overview"
        isExpanded={expandedSections.actions}
        toggleExpand={() => setExpandedSections(prev => ({ ...prev, actions: !prev.actions }))}
      >
        <div className="overflow-x-auto">
          <div className="grid grid-cols-8 gap-2 min-w-[640px]">
            <div></div>
            {daysOfWeek.map(day => (
              <div key={day} className="text-center font-medium text-sm text-text-light dark:text-text-dark">
                {day}
              </div>
            ))}
            {actions.map(action => (
              <React.Fragment key={action}>
                <div className="text-sm font-medium text-text-light dark:text-text-dark truncate">
                  {action}
                </div>
                {daysOfWeek.map((day, index) => (
                  <div key={`${action}-${day}`} className="flex justify-center items-center">
                    <IntensityDot value={data[index]?.action_ratings[action] || 0} />
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </ExpandableSection>

      <ExpandableSection
        title="Weekly Thoughts"
        isExpanded={expandedSections.thoughts}
        toggleExpand={() => setExpandedSections(prev => ({ ...prev, thoughts: !prev.thoughts }))}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {data.map((dayData, index) => (
            <motion.div
              key={index}
              className="bg-tertiary-light dark:bg-tertiary-dark p-4 rounded-lg shadow-sm"
              whileHover={{ scale: 1.02 }}
            >
              <h4 className="font-semibold text-primary-light dark:text-primary-dark mb-2">
                {daysOfWeek[index]}
              </h4>
              <ul className="list-disc list-inside space-y-2">
                {dayData.thoughts.map((thought, thoughtIndex) => (
                  <li key={thoughtIndex} className="text-sm text-text-light dark:text-text-dark">
                    {thought}
                  </li>
                ))}
              </ul>
              {dayData.thoughts.length === 0 && (
                <p className="text-sm text-text-light dark:text-text-dark italic">
                  No thoughts recorded
                </p>
              )}
            </motion.div>
          ))}
        </div>
      </ExpandableSection>

      <div className="mt-4 flex items-center justify-center text-sm text-text-light dark:text-text-dark">
        <Info size={16} className="mr-2" />
        <span>Hover over dots to see intensity values</span>
      </div>
    </div>
  );
};

export default WeekDiaryCard;