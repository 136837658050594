import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import WeeklyDiaryCard from '../components/WeeklyDiaryCard';
import DiaryOverview from '../components/DiaryOverview';
import { authFetch } from '../utils/authFetch';
import { Loader2, AlertCircle, BookOpen, Calendar, Clock } from 'lucide-react';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

const API_ROOT = getApiBaseUrl();

const DiaryOverviewPage = () => {
  const [weeklyData, setWeeklyData] = useState(null);
  const [overviewData, setOverviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeView, setActiveView] = useState('weekly'); // 'weekly' or 'history'

  useEffect(() => {
    fetchDiaryData();
  }, []);

  const fetchDiaryData = async () => {
    setLoading(true);
    setError(null);
    try {
      const [weeklyResponse, overviewResponse] = await Promise.all([
        authFetch(`${API_ROOT}/api/users/weekly-diary/`),
        authFetch(`${API_ROOT}/api/users/diary-overview/`)
      ]);

      if (!weeklyResponse.ok || !overviewResponse.ok) {
        throw new Error('Failed to fetch diary data');
      }

      const weeklyData = await weeklyResponse.json();
      const overviewData = await overviewResponse.json();
      setWeeklyData(weeklyData);
      setOverviewData(overviewData);
    } catch (error) {
      console.error('Error fetching diary data:', error);
      setError('Failed to load diary data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const TabButton = ({ view, label, icon: Icon }) => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => setActiveView(view)}
      className={`flex items-center px-4 py-2 rounded-full ${
        activeView === view
          ? 'bg-primary-light dark:bg-primary-dark text-white'
          : 'bg-secondary-light dark:bg-secondary-dark text-text-light dark:text-text-dark'
      } transition-colors duration-300`}
    >
      <Icon size={18} className="mr-2" />
      {label}
    </motion.button>
  );

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark font-sans">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <motion.h1 
          className="text-4xl font-bold mb-8 flex items-center text-primary-light dark:text-primary-dark"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <BookOpen className="mr-4" size={36} />
          overview
        </motion.h1>

        <div className="mb-8 flex justify-center space-x-4">
          <TabButton view="weekly" label="Weekly Overview" icon={Calendar} />
          <TabButton view="history" label="History" icon={Clock} />
        </div>

        <AnimatePresence mode="wait">
          {loading && (
            <motion.div
              key="loader"
              className="flex justify-center items-center h-64"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Loader2 className="w-12 h-12 animate-spin text-primary-light dark:text-primary-dark" />
            </motion.div>
          )}

          {error && (
            <motion.div
              key="error"
              className="bg-accent-light dark:bg-accent-dark text-white p-4 rounded-lg flex items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <AlertCircle className="mr-4" size={24} />
              <p>{error}</p>
            </motion.div>
          )}

          {!loading && !error && (
            <motion.div 
              key={activeView}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {activeView === 'weekly' && weeklyData && (
                <WeeklyDiaryCard data={weeklyData} />
              )}
              {activeView === 'history' && overviewData && (
                <DiaryOverview data={overviewData} />
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default DiaryOverviewPage;