import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { User, LogOut, Sun, Moon, LogIn, UserPlus, Menu, X, ChevronDown } from 'lucide-react';
import { AuthContext } from '../context/AuthContext';
import { ThemeContext } from '../context/ThemeContext';
import { clearTokens } from '../utils/tokenUtils';
import { authFetch } from '../utils/authFetch';
import ArchiiLogo from './Logo';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

const Header = ({ toggleMobileMenu, isMobileMenuOpen }) => {
  const { isAuthenticated, setIsAuthenticated, logout } = useContext(AuthContext);
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [profile, setProfile] = useState({
    name: '',
    pronouns: '',
    birthday: '',
    profile_picture_url: '',
  });
  const API_BASE_URL = getApiBaseUrl();
  
  const handleLogout = () => {
    clearTokens();
    setIsAuthenticated(false);
    navigate('/login');
  };

  useEffect(() => {
    const fetchProfile = async () => {
      if (!isAuthenticated) return;

      try {
        const response = await authFetch(`${API_BASE_URL}/api/accounts/profile/current_profile/`);
        if (response.ok) {
          const data = await response.json();
          setProfile({
            name: data.name || '',
            pronouns: data.pronouns || '',
            birthday: data.birthday || '',
            profile_picture_url: data.profile_picture_url || '',
          });
        } else {
          console.error('Failed to fetch profile:', await response.text());
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, [isAuthenticated]);

  const defaultPicture = '/default-avatar.png';

  const navItems = [
    { name: 'Features', section: 'core-technology' },
    { name: 'Research', section: 'research-hub' },
    { name: 'Community', section: 'community' },
    { name: 'About', section: 'about' }
  ];
  const isLandingPage = location.pathname === '/';

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-background-light bg-opacity-70 dark:bg-background-dark dark:bg-opacity-70 backdrop-blur-lg z-50 transition-all duration-300">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <div className="flex items-center">
          
          <Link to={isAuthenticated ? "/dashboard" : "/"} className="text-3xl font-bold mr-8 md:ml-4">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-clip-text text-transparent bg-gradient-to-r from-primary-light to-accent-light dark:from-primary-dark dark:to-accent-dark flex items-center"
            >
              
              <ArchiiLogo />
            </motion.div>
          </Link>
          {!isAuthenticated && isLandingPage && (
            <div className="hidden md:flex items-center space-x-8 ml-8">
              {navItems.map((item, index) => (
                <motion.button
                  key={item.name}
                  onClick={() => scrollToSection(item.section)}
                  className="text-text-light dark:text-text-dark hover:text-primary-light dark:hover:text-primary-dark transition-colors relative group"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  {item.name}
                  <motion.span
                    className="absolute bottom-0 left-0 w-full h-0.5 bg-primary-light dark:bg-primary-dark transform origin-left"
                    initial={{ scaleX: 0 }}
                    whileHover={{ scaleX: 1 }}
                    transition={{ duration: 0.3 }}
                  />
                </motion.button>
              ))}
            </div>
          )}
        </div>
        <div className="flex items-center space-x-4">
          {isAuthenticated ? (
            <>
              <div className="relative hidden md:block">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center space-x-2 p-2 rounded-full bg-gradient-to-r from-primary-light to-accent-light dark:from-primary-dark dark:to-accent-dark text-white"
                >
                  <img 
                    src={profile.profile_picture_url || defaultPicture} 
                    alt="Profile" 
                    className="w-8 h-8 rounded-full object-cover"
                  />
                  <ChevronDown size={16} />
                </motion.button>
                <AnimatePresence>
                  {isDropdownOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: -10, scale: 0.95 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{ opacity: 0, y: -10, scale: 0.95 }}
                      transition={{ duration: 0.2 }}
                      className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 overflow-hidden"
                    >
                      <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors">
                        profile
                      </Link>
                      <Link to="/settings" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors">
                        settings
                      </Link>
                      <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors">
                        logout
                      </button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <button
                onClick={toggleMobileMenu}
                className="md:hidden p-2 rounded-md text-text-light dark:text-text-dark hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-light dark:focus:ring-primary-dark"
              >
                {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </>
          ) : (
            <>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="hidden md:block"
              >
                <Link to="/login" className="text-sm bg-primary-light dark:bg-primary-dark text-white px-4 py-2 rounded-full transition-colors">
                  Log In
                </Link>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="hidden md:block"
              >
                <Link to="/signup" className="text-sm bg-accent-light dark:bg-accent-dark text-white px-4 py-2 rounded-full transition-colors">
                  Sign Up
                </Link>
              </motion.div>
            </>
          )}
          <motion.button
            whileHover={{ scale: 1.1, rotate: 180 }}
            whileTap={{ scale: 0.9 }}
            onClick={toggleDarkMode}
            className="p-2 rounded-full bg-tertiary-light dark:bg-tertiary-dark text-text-light dark:text-text-dark hover:text-primary-light dark:hover:text-primary-dark transition-all duration-300"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            {darkMode ? <Sun size={20} /> : <Moon size={20} />}
          </motion.button>
        </div>
      </nav>
    </header>
  );
};

export default Header;