const config = {
    development: 'http://localhost:8000',
    production: 'https://archii.io',
    production2: 'https://archii.ai'
  };
  
  export const getApiBaseUrl = () => {
    const hostname = window.location.hostname;
    
    if (hostname === 'localhost' || hostname === '127.0.0.1') {
      return config.development;
    }
    
    if (hostname === 'archii.ai') {
      return config.production2;
    }
    
    return config.production;
  };