import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import CheckIn from './pages/CheckIn';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Chatbot from './pages/Chatbot';
import Profile from './pages/Profile';
import AssessmentsPage from './pages/Assessments';
import History from './pages/History';
import Settings from './pages/Settings';
import Landing3 from './pages/Landing3';
import Overview from './pages/Overview';
import PrivateRoute from './components/PrivateRoute';
import SessionExpiredPopup from './components/SessionExpiredPopup';
import GlobalDashboard from './pages/GlobalDashboard';
import LowercaseWrapper from './components/LowerCaseWrapper';

function AppContent() {
  const { isAuthenticated, sessionExpired, setSessionExpired } = useContext(AuthContext);
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <LowercaseWrapper>
      <div className="flex flex-col min-h-screen bg-background-light dark:bg-background-dark">
        <Header 
          className="fixed top-0 left-0 right-0 z-50" 
          toggleMobileMenu={toggleMobileMenu}
          isMobileMenuOpen={isMobileMenuOpen}
        />
        <div className="flex flex-1 pt-16">
          {isAuthenticated && !isLandingPage && (
            <Sidebar 
              className={`fixed left-0 top-16 bottom-0 z-40 transition-transform duration-300 ease-in-out transform ${
                isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
              }`} 
              headerClassName="bg-background-light dark:bg-background-dark"
            />
          )}
          <main className={`flex-1 overflow-y-auto transition-all duration-300 ${isAuthenticated && !isLandingPage ? 'md:ml-64' : ''}`}>
            <Routes>
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/chatbot"
                element={
                  <PrivateRoute>
                    <Chatbot />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/history"
                element={
                  <PrivateRoute>
                    <History />
                  </PrivateRoute>
                }
              />
              <Route
                path="/assessments"
                element={
                  <PrivateRoute>
                    <AssessmentsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/checkin"
                element={
                  <PrivateRoute>
                    <CheckIn />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/overview"
                element={
                  <PrivateRoute>
                    <Overview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/global-dashboard"
                element={
                  <PrivateRoute>
                    <GlobalDashboard />
                  </PrivateRoute>
                }
              />
              <Route path="/" element={<Landing3 />} />
            </Routes>
          </main>
        </div>
        {sessionExpired && (
          <SessionExpiredPopup onClose={() => setSessionExpired(false)} />
        )}
      </div>
    </LowercaseWrapper>
  );
}

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <AppContent />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;