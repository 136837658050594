import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import DiaryCard from '../components/DiaryCard';
import EmotionWheel from '../components/EmotionWheel';
import EmotionWeb from '../components/EmotionGrid';
import { authFetch } from '../utils/authFetch';
import { Loader2, Calendar, AlertCircle, CheckCircle } from 'lucide-react';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

const API_ROOT = getApiBaseUrl();

const CheckIn = () => {
  const [moodLog, setMoodLog] = useState(null);
  const [diaryCard, setDiaryCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [activeSection, setActiveSection] = useState('mood');

  useEffect(() => {
    fetchTodayLogs();
  }, []);

  const fetchTodayLogs = async () => {
    setLoading(true);
    try {
      const userDatetime = new Date().toISOString();
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await authFetch(`${API_ROOT}/api/users/today-logs/?user_datetime=${encodeURIComponent(userDatetime)}&timezone=${encodeURIComponent(userTimezone)}`);
      if (!response.ok) throw new Error('Failed to fetch today\'s logs');
      const data = await response.json();
      
      // Check if the mood log is from today
      const today = new Date().toDateString();
      const moodLogDate = new Date(data.mood_log.date).toDateString();
      
      if (moodLogDate === today) {
        setMoodLog(data.mood_log);
      } else {
        setMoodLog(null); // Reset mood log if it's not from today
      }
      
      setDiaryCard(data.diary_card);
    } catch (error) {
      console.error('Error fetching today\'s logs:', error);
      setMessage({ type: 'error', text: 'Failed to load today\'s logs. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleMoodSubmit = async (moodData) => {
    try {
      const response = await authFetch(`${API_ROOT}/api/users/log-mood/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...moodData, date: new Date().toISOString().split('T')[0] }),
      });
      if (!response.ok) throw new Error('Failed to submit mood log');
      setMoodLog(await response.json());
      setMessage({ type: 'success', text: 'Mood log submitted successfully.' });
    } catch (error) {
      console.error('Error submitting mood log:', error);
      setMessage({ type: 'error', text: 'Failed to submit mood log. Please try again.' });
    }
  };

  const handleDiaryCardSubmit = async (diaryCardData) => {
    try {
      const response = await authFetch(`${API_ROOT}/api/users/log-diary-card/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...diaryCardData, date: new Date().toISOString().split('T')[0] }),
      });
      if (!response.ok) throw new Error('Failed to submit diary card');
      setDiaryCard(await response.json());
      setMessage({ type: 'success', text: 'Diary card submitted successfully.' });
    } catch (error) {
      console.error('Error submitting diary card:', error);
      setMessage({ type: 'error', text: 'Failed to submit diary card. Please try again.' });
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-background-light dark:bg-background-dark">
        <Loader2 className="h-12 w-12 animate-spin text-primary-light dark:text-primary-dark" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark font-sans">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-3xl sm:text-4xl font-bold text-primary-light dark:text-primary-dark flex items-center justify-center mb-8">
            <Calendar className="mr-2" /> Daily Check-In
          </h1>
        </motion.div>
        
        <AnimatePresence>
          {message && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className={`p-4 mb-6 rounded-md flex items-center ${
                message.type === 'error' 
                  ? 'bg-accent-light dark:bg-accent-dark' 
                  : 'bg-green-100 dark:bg-green-800'
              } text-text-light dark:text-text-dark`}
            >
              {message.type === 'error' ? (
                <AlertCircle className="mr-2 flex-shrink-0" />
              ) : (
                <CheckCircle className="mr-2 flex-shrink-0" />
              )}
              {message.text}
            </motion.div>
          )}
        </AnimatePresence>

        <div className="flex justify-center space-x-4 mb-6">
          <button
            onClick={() => setActiveSection('mood')}
            className={`px-4 py-2 rounded-full transition-colors ${
              activeSection === 'mood'
                ? 'bg-primary-light dark:bg-primary-dark text-white'
                : 'bg-tertiary-light dark:bg-tertiary-dark'
            }`}
          >
            Mood Check
          </button>
          <button
            onClick={() => setActiveSection('diary')}
            className={`px-4 py-2 rounded-full transition-colors ${
              activeSection === 'diary'
                ? 'bg-primary-light dark:bg-primary-dark text-white'
                : 'bg-tertiary-light dark:bg-tertiary-dark'
            }`}
          >
            Diary Card
          </button>
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="p-4 sm:p-6 rounded-lg"
          >
            {activeSection === 'mood' && (
              <EmotionWheel onSubmit={handleMoodSubmit} initialData={moodLog} />
     
            )}

            {activeSection === 'diary' && (
              <DiaryCard onSubmit={handleDiaryCardSubmit} initialData={diaryCard} />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default CheckIn;