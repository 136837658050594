import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LayoutDashboard, MessageSquare, UserCircle, Globe, ClipboardList, LineChart, History, Settings, LogOut, CheckCircle } from 'lucide-react';
import { ThemeContext } from '../context/ThemeContext';
import { AuthContext } from '../context/AuthContext';

const Sidebar = ({ className, headerClassName }) => {
  const location = useLocation();
  const { darkMode } = useContext(ThemeContext);
  const { isAuthenticated, logout } = useContext(AuthContext);

  const NavItem = ({ to, icon: Icon, label, onClick }) => (
    <motion.li
      whileHover={{ scale: 1.05, x: 5 }}
      whileTap={{ scale: 0.95 }}
      className={`mb-2 ${location.pathname === to ? 'bg-primary-light dark:bg-primary-dark text-white' : ''} rounded-lg overflow-hidden`}
    >
      {onClick ? (
        <button
          onClick={onClick}
          className={`w-full flex items-center p-3 ${
            location.pathname === to
              ? 'text-white'
              : 'text-gray-600 dark:text-gray-300 hover:text-primary-light dark:hover:text-primary-dark'
          } hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-300`}
        >
          <Icon size={20} className="mr-3 flex-shrink-0" />
          <span className="whitespace-nowrap">{label}</span>
        </button>
      ) : (
        <Link
          to={to}
          className={`flex items-center p-3 ${
            location.pathname === to
              ? 'text-white'
              : 'text-gray-600 dark:text-gray-300 hover:text-primary-light dark:hover:text-primary-dark'
          } hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-300`}
        >
          <Icon size={20} className="mr-3 flex-shrink-0" />
          <span className="whitespace-nowrap">{label}</span>
        </Link>
      )}
    </motion.li>
  );

  if (!isAuthenticated || location.pathname === '/') {
    return null;
  }

  return (
    <div className={`${className} w-64 bg-background-light bg-opacity-70 dark:bg-background-dark dark:bg-opacity-70 backdrop-blur-lg shadow-lg`}>
      <div className="h-full flex flex-col p-4">
        <ul className="space-y-2">
          <NavItem to="/dashboard" icon={LayoutDashboard} label="Dashboard" />
          <NavItem to="/chatbot" icon={MessageSquare} label="chat" />
          <NavItem to="/checkin" icon={CheckCircle} label="Check In" />
          <NavItem to="/assessments" icon={ClipboardList} label="Assessments" />
          <NavItem to="/overview" icon={LineChart} label="Overview" />
          <NavItem to="/history" icon={History} label="History" />
          <NavItem to="/global-dashboard" icon={Globe} label="Global Trends" />
          {/* <NavItem to="/profile" icon={UserCircle} label="Profile" />
          <NavItem to="/settings" icon={Settings} label="Settings" />
          <NavItem icon={LogOut} label="Logout" onClick={logout} /> */}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
