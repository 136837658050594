import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiCheckCircle, FiChevronRight } from 'react-icons/fi';
import { Loader2, AlertCircle } from 'lucide-react';
import { authFetch } from '../utils/authFetch';
import "tailwindcss/tailwind.css";
import { getApiBaseUrl } from '../utils/apiBaseUrl';

const API_ROOT = getApiBaseUrl();
const API_ENDPOINT = `${API_ROOT}/api/users/submit-assessment/`;

const AssessmentComponent = ({ questions, responses, setResponses, onComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const updateResponses = (questionIndex, value) => {
    setResponses(prev => {
      const newResponses = {
        ...prev,
        [`q${questionIndex + 1}`]: value
      };
      console.log('Updating responses:', newResponses);
      return newResponses;
    });
  };

  const handleResponse = (value) => {
    updateResponses(currentQuestion, value);
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const finalResponses = {
        ...responses,
        [`q${currentQuestion + 1}`]: value
      };
      onComplete(finalResponses);
    }
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={currentQuestion}
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -50 }}
        transition={{ duration: 0.3 }}
        className="max-w-2xl mx-auto"
      >
        <h3 className="text-xl font-semibold mb-6 text-text-light dark:text-text-dark">
          {questions[currentQuestion].question}
        </h3>
        <div className="space-y-4">
          {questions[currentQuestion].options.map((option, index) => (
            option.isInput ? (
              <motion.input
                key={index}
                type="text"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="w-full p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 text-left text-text-light dark:text-text-dark focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark"
                onKeyPress={(e) => e.key === 'Enter' && handleResponse(e.target.value)}
                placeholder={option.text}
              />
            ) : (
              <motion.button
                key={index}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="w-full p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 text-left text-text-light dark:text-text-dark focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark"
                onClick={() => handleResponse(option.value)}
              >
                {option.text}
              </motion.button>
            )
          ))}
        </div>
        <div className="mt-8 text-sm text-text-light dark:text-text-dark opacity-75">
          Question {currentQuestion + 1} of {questions.length}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const MHI = ({ responses, setResponses, onComplete }) => {
  const questions = [
    {
      question: "How happy, satisfied, or pleased have you been with your personal life during the past month?",
      options: [
        { text: "Extremely happy", value: 6 },
        { text: "Very happy", value: 5 },
        { text: "Fairly happy", value: 4 },
        { text: "Satisfied", value: 3 },
        { text: "Somewhat dissatisfied", value: 2 },
        { text: "Very dissatisfied", value: 1 },
      ]
    },
    {
      question: "How much of the time have you felt lonely during the past month?",
      options: [
        { text: "All of the time", value: 1 },
        { text: "Most of the time", value: 2 },
        { text: "A good bit of the time", value: 3 },
        { text: "Some of the time", value: 4 },
        { text: "A little of the time", value: 5 },
        { text: "None of the time", value: 6 },
      ]
    },
    {
      question: "How often did you become nervous or jumpy when faced with excitement or unexpected situations during the past month?",
      options: [
        { text: "Always", value: 1 },
        { text: "Very often", value: 2 },
        { text: "Fairly often", value: 3 },
        { text: "Sometimes", value: 4 },
        { text: "Almost never", value: 5 },
        { text: "Never", value: 6 },
      ]
    },
    {
      question: "During the past month, how much of the time have you felt that the future looks hopeful and promising?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "How much of the time, during the past month, has your daily life been full of things that were interesting to you?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "How much of the time, during the past month, did you feel relaxed and free from tension?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "During the past month, how much of the time have you generally enjoyed the things you do?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "During the past month, have you had any reason to wonder if you were losing your mind, or losing control over the way you act, talk, think, feel, or of your memory?",
      options: [
        { text: "No, not at all", value: 6 },
        { text: "Maybe a little", value: 5 },
        { text: "Yes, but not enough to be concerned or worried about it", value: 4 },
        { text: "Yes, and I have been a little concerned", value: 3 },
        { text: "Yes, and I am quite concerned", value: 2 },
        { text: "Yes, and I am very much concerned about it", value: 1 },
      ]
    },
    {
      question: "Did you feel depressed during the past month?",
      options: [
        { text: "Yes, to the point that I did not care about anything for days at a time", value: 1 },
        { text: "Yes, very depressed almost every day", value: 2 },
        { text: "Yes, quite depressed several times", value: 3 },
        { text: "Yes, a little depressed now and then", value: 4 },
        { text: "No, never felt depressed at all", value: 5 },
      ]
    },
    {
      question: "During the past month, how much of the time have you felt loved and wanted?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "How much of the time, during the past month, have you been a very nervous person?",
      options: [
        { text: "All of the time", value: 1 },
        { text: "Most of the time", value: 2 },
        { text: "A good bit of the time", value: 3 },
        { text: "Some of the time", value: 4 },
        { text: "A little of the time", value: 5 },
        { text: "None of the time", value: 6 },
      ]
    },
    {
      question: "When you have got up in the morning, this past month, about how often did you expect to have an interesting day?",
      options: [
        { text: "Always", value: 6 },
        { text: "Very often", value: 5 },
        { text: "Fairly often", value: 4 },
        { text: "Sometimes", value: 3 },
        { text: "Almost never", value: 2 },
        { text: "Never", value: 1 },
      ]
    },
    {
      question: "During the past month, how much of the time have you felt tense or 'high-strung'?",
      options: [
        { text: "All of the time", value: 1 },
        { text: "Most of the time", value: 2 },
        { text: "A good bit of the time", value: 3 },
        { text: "Some of the time", value: 4 },
        { text: "A little of the time", value: 5 },
        { text: "None of the time", value: 6 },
      ]
    },
    {
      question: "During the past month, have you been in firm control of your behavior, thoughts, emotions, feelings?",
      options: [
        { text: "Yes, very definitely", value: 6 },
        { text: "Yes, for the most part", value: 5 },
        { text: "Yes, I guess so", value: 4 },
        { text: "No, not too well", value: 3 },
        { text: "No, and I am somewhat disturbed", value: 2 },
        { text: "No, and I am very disturbed", value: 1 },
      ]
    },
    {
      question: "During the past month, how often did your hands shake when you tried to do something?",
      options: [
        { text: "Always", value: 1 },
        { text: "Very often", value: 2 },
        { text: "Fairly often", value: 3 },
        { text: "Sometimes", value: 4 },
        { text: "Almost never", value: 5 },
        { text: "Never", value: 6 },
      ]
    },
    {
      question: "During the past month, how often did you feel that you had nothing to look forward to?",
      options: [
        { text: "Always", value: 1 },
        { text: "Very often", value: 2 },
        { text: "Fairly often", value: 3 },
        { text: "Sometimes", value: 4 },
        { text: "Almost never", value: 5 },
        { text: "Never", value: 6 },
      ]
    },
    {
      question: "How much of the time, during the past month, have you felt calm and peaceful?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "How much of the time, during the past month, have you felt emotionally stable?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "How much of the time, during the past month, have you felt downhearted and blue?",
      options: [
        { text: "All of the time", value: 1 },
        { text: "Most of the time", value: 2 },
        { text: "A good bit of the time", value: 3 },
        { text: "Some of the time", value: 4 },
        { text: "A little of the time", value: 5 },
        { text: "None of the time", value: 6 },
      ]
    },
    {
      question: "How often have you felt like crying, during the past month?",
      options: [
        { text: "Always", value: 1 },
        { text: "Very often", value: 2 },
        { text: "Fairly often", value: 3 },
        { text: "Sometimes", value: 4 },
        { text: "Almost never", value: 5 },
        { text: "Never", value: 6 },
      ]
    },
    {
      question: "During the past month, how often have you felt that others would be better off if you were dead?",
      options: [
        { text: "Always", value: 1 },
        { text: "Very often", value: 2 },
        { text: "Fairly often", value: 3 },
        { text: "Sometimes", value: 4 },
        { text: "Almost never", value: 5 },
        { text: "Never", value: 6 },
      ]
    },
    {
      question: "How much of the time, during the past month, were you able to relax without difficulty?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "During the past month, how much of the time did you feel that your love relationships, loving and being loved, were full and complete?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "How often, during the past month, did you feel that nothing turned out for you the way you wanted it to?",
      options: [
        { text: "Always", value: 1 },
        { text: "Very often", value: 2 },
        { text: "Fairly often", value: 3 },
        { text: "Sometimes", value: 4 },
        { text: "Almost never", value: 5 },
        { text: "Never", value: 6 },
      ]
    },
    {
      question: "How much have you been bothered by nervousness, or your 'nerves', during the past month?",
      options: [
        { text: "Extremely so, to the point where I could not take care of things", value: 1 },
        { text: "Very much bothered", value: 2 },
        { text: "Bothered quite a bit by nerves", value: 3 },
        { text: "Bothered some, enough to notice", value: 4 },
        { text: "Bothered just a little by nerves", value: 5 },
        { text: "Not bothered at all by this", value: 6 },
      ]
    },
    {
      question: "During the past month, how much of the time has living been a wonderful adventure for you?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "How often, during the past month, have you felt so down in the dumps that nothing could cheer you up?",
      options: [
        { text: "Always", value: 1 },
        { text: "Very often", value: 2 },
        { text: "Fairly often", value: 3 },
        { text: "Sometimes", value: 4 },
        { text: "Almost never", value: 5 },
        { text: "Never", value: 6 },
      ]
    },
    {
      question: "During the past month, did you ever think about taking your own life?",
      options: [
        { text: "Yes, very often", value: 1 },
        { text: "Yes, fairly often", value: 2 },
        { text: "Yes, a couple of times", value: 3 },
        { text: "Yes, at one time", value: 4 },
        { text: "No, never", value: 6 },
      ]
    },
    {
      question: "During the past month, how much of the time have you felt restless, fidgety, or impatient?",
      options: [
        { text: "All of the time", value: 1 },
        { text: "Most of the time", value: 2 },
        { text: "A good bit of the time", value: 3 },
        { text: "Some of the time", value: 4 },
        { text: "A little of the time", value: 5 },
        { text: "None of the time", value: 6 },
      ]
    },
    {
      question: "During the past month, how much of the time have you been moody or brooded about things?",
      options: [
        { text: "All of the time", value: 1 },
        { text: "Most of the time", value: 2 },
        { text: "A good bit of the time", value: 3 },
        { text: "Some of the time", value: 4 },
        { text: "A little of the time", value: 5 },
        { text: "None of the time", value: 6 },
      ]
    },
    {
      question: "How much of the time, during the past month, have you felt cheerful, lighthearted?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "During the past month, how often did you get rattled, upset, or flustered?",
      options: [
        { text: "Always", value: 1 },
        { text: "Very often", value: 2 },
        { text: "Fairly often", value: 3 },
        { text: "Sometimes", value: 4 },
        { text: "Almost never", value: 5 },
        { text: "Never", value: 6 },
      ]
    },
    {
      question: "During the past month, have you been anxious or worried?",
      options: [
        { text: "Yes, extremely to the point of being sick or almost sick", value: 1 },
        { text: "Yes, very much so", value: 2 },
        { text: "Yes, quite a bit", value: 3 },
        { text: "Yes, some more than usual", value: 4 },
        { text: "Yes, a little bit", value: 5 },
        { text: "No, not at all", value: 6 },
      ]
    },
    {
      question: "During the past month, how much of the time were you a happy person?",
      options: [
        { text: "All of the time", value: 6 },
        { text: "Most of the time", value: 5 },
        { text: "A good bit of the time", value: 4 },
        { text: "Some of the time", value: 3 },
        { text: "A little of the time", value: 2 },
        { text: "None of the time", value: 1 },
      ]
    },
    {
      question: "How often during the past month did you find yourself trying to calm down?",
      options: [
        { text: "Always", value: 1 },
        { text: "Very often", value: 2 },
        { text: "Fairly often", value: 3 },
        { text: "Sometimes", value: 4 },
        { text: "Almost never", value: 5 },
        { text: "Never", value: 6 },
      ]
    },
    {
      question: "During the past month, how much of the time have you been in low or very low spirits?",
      options: [
        { text: "All of the time", value: 1 },
        { text: "Most of the time", value: 2 },
        { text: "A good bit of the time", value: 3 },
        { text: "Some of the time", value: 4 },
        { text: "A little of the time", value: 5 },
        { text: "None of the time", value: 6 },
      ]
    },
    {
      question: "How often, during the past month, have you been waking up feeling fresh and rested?",
      options: [
        { text: "Always, every day", value: 6 },
        { text: "Almost every day", value: 5 },
        { text: "Most days", value: 4 },
        { text: "Some days, but usually not", value: 3 },
        { text: "Hardly ever", value: 2 },
        { text: "Never wake up feeling rested", value: 1 },
      ]
    },
    {
      question: "During the past month, have you been under or felt you were under any strain, stress, or pressure?",
      options: [
        { text: "Yes, almost more than I could stand or bear", value: 1 },
        { text: "Yes, quite a bit of pressure", value: 2 },
        { text: "Yes, some more than usual", value: 3 },
        { text: "Yes, about usual", value: 4 },
        { text: "Yes, a little bit", value: 5 },
        { text: "No, not at all", value: 6 },
      ]
    },
  ];

  return <AssessmentComponent questions={questions} responses={responses} setResponses={setResponses} onComplete={onComplete} />;
};

const GAF = ({ responses, setResponses, onComplete }) => {
  const questions = [
    {
      question: "Consider psychological, social, and occupational functioning on a hypothetical continuum of mental health-illness. Do not include impairment in functioning due to physical (or environmental) limitations.",
      options: [
        { text: "Superior functioning in a wide range of activities (91-100)", value: 95 },
        { text: "Absent or minimal symptoms (81-90)", value: 85 },
        { text: "No more than slight impairment in social, occupational, or school functioning (71-80)", value: 75 },
        { text: "Some mild symptoms OR some difficulty in social, occupational, or school functioning (61-70)", value: 65 },
        { text: "Moderate symptoms OR moderate difficulty in social, occupational, or school functioning (51-60)", value: 55 },
        { text: "Serious symptoms OR any serious impairment in social, occupational, or school functioning (41-50)", value: 45 },
        { text: "Some impairment in reality testing or communication OR major impairment in several areas (31-40)", value: 35 },
        { text: "Behavior is considerably influenced by delusions or hallucinations OR serious impairment in communication or judgment (21-30)", value: 25 },
        { text: "Some danger of hurting self or others OR occasionally fails to maintain minimal personal hygiene (11-20)", value: 15 },
        { text: "Persistent danger of severely hurting self or others OR persistent inability to maintain minimal personal hygiene (1-10)", value: 5 },
      ]
    }
  ];

  return <AssessmentComponent questions={questions} responses={responses} setResponses={setResponses} onComplete={onComplete} />;
};

const BDIII = ({ responses, setResponses, onComplete }) => {
  const questions = [
    {
      question: "Sadness",
      options: [
        { text: "I do not feel sad", value: 0 },
        { text: "I feel sad much of the time", value: 1 },
        { text: "I am sad all the time", value: 2 },
        { text: "I am so sad or unhappy that I can't stand it", value: 3 },
      ]
    },
    {
      question: "Pessimism",
      options: [
        { text: "I am not discouraged about my future", value: 0 },
        { text: "I feel more discouraged about my future than I used to be", value: 1 },
        { text: "I do not expect things to work out for me", value: 2 },
        { text: "I feel my future is hopeless and will only get worse", value: 3 },
      ]
    },
    {
      question: "Past Failure",
      options: [
        { text: "I do not feel like a failure", value: 0 },
        { text: "I have failed more than I should have", value: 1 },
        { text: "As I look back, I see a lot of failures", value: 2 },
        { text: "I feel I am a total failure as a person", value: 3 },
      ]
    },
    {
      question: "Loss of Pleasure",
      options: [
        { text: "I get as much pleasure as I ever did from the things I enjoy", value: 0 },
        { text: "I don't enjoy things as much as I used to", value: 1 },
        { text: "I get very little pleasure from the things I used to enjoy", value: 2 },
        { text: "I can't get any pleasure from the things I used to enjoy", value: 3 },
      ]
    },
    {
      question: "Guilty Feelings",
      options: [
        { text: "I don't feel particularly guilty", value: 0 },
        { text: "I feel guilty over many things I have done or should have done", value: 1 },
        { text: "I feel quite guilty most of the time", value: 2 },
        { text: "I feel guilty all of the time", value: 3 },
      ]
    },
    {
      question: "Punishment Feelings",
      options: [
        { text: "I don't feel I am being punished", value: 0 },
        { text: "I feel I may be punished", value: 1 },
        { text: "I expect to be punished", value: 2 },
        { text: "I feel I am being punished", value: 3 },
      ]
    },
    {
      question: "Self-Dislike",
      options: [
        { text: "I feel the same about myself as ever", value: 0 },
        { text: "I have lost confidence in myself", value: 1 },
        { text: "I am disappointed in myself", value: 2 },
        { text: "I dislike myself", value: 3 },
      ]
    },
    {
      question: "Self-Criticalness",
      options: [
        { text: "I don't criticize or blame myself more than usual", value: 0 },
        { text: "I am more critical of myself than I used to be", value: 1 },
        { text: "I criticize myself for all of my faults", value: 2 },
        { text: "I blame myself for everything bad that happens", value: 3 },
      ]
    },
    {
      question: "Suicidal Thoughts or Wishes",
      options: [
        { text: "I don't have any thoughts of killing myself", value: 0 },
        { text: "I have thoughts of killing myself, but I would not carry them out", value: 1 },
        { text: "I would like to kill myself", value: 2 },
        { text: "I would kill myself if I had the chance", value: 3 },
      ]
    },
    {
      question: "Crying",
      options: [
        { text: "I don't cry anymore than I used to", value: 0 },
        { text: "I cry more than I used to", value: 1 },
        { text: "I cry over every little thing", value: 2 },
        { text: "I feel like crying, but I can't", value: 3 },
      ]
    },
    {
      question: "Agitation",
      options: [
        { text: "I am no more restless or wound up than usual", value: 0 },
        { text: "I feel more restless or wound up than usual", value: 1 },
        { text: "I am so restless or agitated that it's hard to stay still", value: 2 },
        { text: "I am so restless or agitated that I have to keep moving or doing something", value: 3 },
      ]
    },
    {
      question: "Loss of Interest",
      options: [
        { text: "I have not lost interest in other people or activities", value: 0 },
        { text: "I am less interested in other people or things than before", value: 1 },
        { text: "I have lost most of my interest in other people or things", value: 2 },
        { text: "It's hard to get interested in anything", value: 3 },
      ]
    },
    {
      question: "Indecisiveness",
      options: [
        { text: "I make decisions about as well as ever", value: 0 },
        { text: "I find it more difficult to make decisions than usual", value: 1 },
        { text: "I have much greater difficulty in making decisions than I used to", value: 2 },
        { text: "I have trouble making any decisions", value: 3 },
      ]
    },
    {
      question: "Worthlessness",
      options: [
        { text: "I do not feel I am worthless", value: 0 },
        { text: "I don't consider myself as worthwhile and useful as I used to", value: 1 },
        { text: "I feel more worthless as compared to other people", value: 2 },
        { text: "I feel utterly worthless", value: 3 },
      ]
    },
    {
      question: "Loss of Energy",
      options: [
        { text: "I have as much energy as ever", value: 0 },
        { text: "I have less energy than I used to have", value: 1 },
        { text: "I don't have enough energy to do very much", value: 2 },
        { text: "I don't have enough energy to do anything", value: 3 },
      ]
    },
    {
      question: "Changes in Sleeping Pattern",
      options: [
        { text: "I have not experienced any change in my sleeping pattern", value: 0 },
        { text: "I sleep somewhat more than usual", value: 1 },
        { text: "I sleep somewhat less than usual", value: 1 },
        { text: "I sleep a lot more than usual", value: 2 },
        { text: "I sleep a lot less than usual", value: 2 },
        { text: "I sleep most of the day", value: 3 },
        { text: "I wake up 1-2 hours early and can't get back to sleep", value: 3 },
      ]
    },
    {
      question: "Irritability",
      options: [
        { text: "I am no more irritable than usual", value: 0 },
        { text: "I am more irritable than usual", value: 1 },
        { text: "I am much more irritable than usual", value: 2 },
        { text: "I am irritable all the time", value: 3 },
      ]
    },
    {
      question: "Changes in Appetite",
      options: [
        { text: "I have not experienced any change in my appetite", value: 0 },
        { text: "My appetite is somewhat less than usual", value: 1 },
        { text: "My appetite is somewhat greater than usual", value: 1 },
        { text: "My appetite is much less than before", value: 2 },
        { text: "My appetite is much greater than usual", value: 2 },
        { text: "I have no appetite at all", value: 3 },
        { text: "I crave food all the time", value: 3 },
      ]
    },
    {
      question: "Concentration Difficulty",
      options: [
        { text: "I can concentrate as well as ever", value: 0 },
        { text: "I can't concentrate as well as usual", value: 1 },
        { text: "It's hard to keep my mind on anything for very long", value: 2 },
        { text: "I find I can't concentrate on anything", value: 3 },
      ]
    },
    {
      question: "Tiredness or Fatigue",
      options: [
        { text: "I am no more tired or fatigued than usual", value: 0 },
        { text: "I get more tired or fatigued more easily than usual", value: 1 },
        { text: "I am too tired or fatigued to do a lot of the things I used to do", value: 2 },
        { text: "I am too tired or fatigued to do most of the things I used to do", value: 3 },
      ]
    },
    {
      question: "Loss of Interest in Sex",
      options: [
        { text: "I have not noticed any recent change in my interest in sex", value: 0 },
        { text: "I am less interested in sex than I used to be", value: 1 },
        { text: "I am much less interested in sex now", value: 2 },
        { text: "I have lost interest in sex completely", value: 3 },
      ]
    },
  ];

  return <AssessmentComponent questions={questions} responses={responses} setResponses={setResponses} onComplete={onComplete} />;
};

const GAD7 = ({ responses, setResponses, onComplete }) => {
  const questions = [
    {
      question: "Feeling nervous, anxious, or on edge",
      options: [
        { text: "Not at all", value: 0 },
        { text: "Several days", value: 1 },
        { text: "More than half the days", value: 2 },
        { text: "Nearly every day", value: 3 },
      ]
    },
    {
      question: "Not being able to stop or control worrying",
      options: [
        { text: "Not at all", value: 0 },
        { text: "Several days", value: 1 },
        { text: "More than half the days", value: 2 },
        { text: "Nearly every day", value: 3 },
      ]
    },
    {
      question: "Worrying too much about different things",
      options: [
        { text: "Not at all", value: 0 },
        { text: "Several days", value: 1 },
        { text: "More than half the days", value: 2 },
        { text: "Nearly every day", value: 3 },
      ]
    },
    {
      question: "Trouble relaxing",
      options: [
        { text: "Not at all", value: 0 },
        { text: "Several days", value: 1 },
        { text: "More than half the days", value: 2 },
        { text: "Nearly every day", value: 3 },
      ]
    },
    {
      question: "Being so restless that it's hard to sit still",
      options: [
        { text: "Not at all", value: 0 },
        { text: "Several days", value: 1 },
        { text: "More than half the days", value: 2 },
        { text: "Nearly every day", value: 3 },
      ]
    },
    {
      question: "Becoming easily annoyed or irritable",
      options: [
        { text: "Not at all", value: 0 },
        { text: "Several days", value: 1 },
        { text: "More than half the days", value: 2 },
        { text: "Nearly every day", value: 3 },
      ]
    },
    {
      question: "Feeling afraid as if something awful might happen",
      options: [
        { text: "Not at all", value: 0 },
        { text: "Several days", value: 1 },
        { text: "More than half the days", value: 2 },
        { text: "Nearly every day", value: 3 },
      ]
    }
  ];

  return <AssessmentComponent questions={questions} responses={responses} setResponses={setResponses} onComplete={onComplete} />;
};

const PSS = ({ responses, setResponses, onComplete }) => {
  const questions = [
    {
      question: "In the last month, how often have you been upset because of something that happened unexpectedly?",
      options: [
        { text: "Never", value: 0 },
        { text: "Almost Never", value: 1 },
        { text: "Sometimes", value: 2 },
        { text: "Fairly Often", value: 3 },
        { text: "Very Often", value: 4 },
      ]
    },
    {
      question: "In the last month, how often have you felt that you were unable to control the important things in your life?",
      options: [
        { text: "Never", value: 0 },
        { text: "Almost Never", value: 1 },
        { text: "Sometimes", value: 2 },
        { text: "Fairly Often", value: 3 },
        { text: "Very Often", value: 4 },
      ]
    },
    {
      question: "In the last month, how often have you felt nervous and stressed?",
      options: [
        { text: "Never", value: 0 },
        { text: "Almost Never", value: 1 },
        { text: "Sometimes", value: 2 },
        { text: "Fairly Often", value: 3 },
        { text: "Very Often", value: 4 },
      ]
    },
    {
      question: "In the last month, how often have you felt confident about your ability to handle your personal problems?",
      options: [
        { text: "Never", value: 4 },
        { text: "Almost Never", value: 3 },
        { text: "Sometimes", value: 2 },
        { text: "Fairly Often", value: 1 },
        { text: "Very Often", value: 0 },
      ]
    },
    {
      question: "In the last month, how often have you felt that things were going your way?",
      options: [
        { text: "Never", value: 4 },
        { text: "Almost Never", value: 3 },
        { text: "Sometimes", value: 2 },
        { text: "Fairly Often", value: 1 },
        { text: "Very Often", value: 0 },
      ]
    },
    {
      question: "In the last month, how often have you found that you could not cope with all the things that you had to do?",
      options: [
        { text: "Never", value: 0 },
        { text: "Almost Never", value: 1 },
        { text: "Sometimes", value: 2 },
        { text: "Fairly Often", value: 3 },
        { text: "Very Often", value: 4 },
      ]
    },
    {
      question: "In the last month, how often have you been able to control irritations in your life?",
      options: [
        { text: "Never", value: 4 },
        { text: "Almost Never", value: 3 },
        { text: "Sometimes", value: 2 },
        { text: "Fairly Often", value: 1 },
        { text: "Very Often", value: 0 },
      ]
    },
    {
      question: "In the last month, how often have you felt that you were on top of things?",
      options: [
        { text: "Never", value: 4 },
        { text: "Almost Never", value: 3 },
        { text: "Sometimes", value: 2 },
        { text: "Fairly Often", value: 1 },
        { text: "Very Often", value: 0 },
      ]
    },
    {
      question: "In the last month, how often have you been angered because of things that happened that were outside of your control?",
      options: [
        { text: "Never", value: 0 },
        { text: "Almost Never", value: 1 },
        { text: "Sometimes", value: 2 },
        { text: "Fairly Often", value: 3 },
        { text: "Very Often", value: 4 },
      ]
    },
    {
      question: "In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?",
      options: [
        { text: "Never", value: 0 },
        { text: "Almost Never", value: 1 },
        { text: "Sometimes", value: 2 },
        { text: "Fairly Often", value: 3 },
        { text: "Very Often", value: 4 },
      ]
    }
  ];

  return <AssessmentComponent questions={questions} responses={responses} setResponses={setResponses} onComplete={onComplete} />;
};

const CSSRS = ({ responses, setResponses, onComplete }) => {
  const questions = [
    {
      question: "Have you wished you were dead or wished you could go to sleep and not wake up?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Have you actually had any thoughts of killing yourself?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Have you been thinking about how you might do this?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Have you had these thoughts and had some intention of acting on them?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Have you started to work out or worked out the details of how to kill yourself? Do you intend to carry out this plan?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Have you ever done anything, started to do anything, or prepared to do anything to end your life?",
      options: [
        { text: "Yes, within the last 3 months", value: 2 },
        { text: "Yes, but not within the last 3 months", value: 1 },
        { text: "No", value: 0 },
      ]
    }
  ];

  return <AssessmentComponent questions={questions} responses={responses} setResponses={setResponses} onComplete={onComplete} />;
};

const SAFET = ({ responses, setResponses, onComplete }) => {
  const questions = [
    {
      question: "Are you having thoughts of suicide?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Do you have a plan for suicide?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Do you have access to means to carry out your plan?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Have you made any preparations to carry out your plan?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Have you attempted suicide in the past?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Are you currently experiencing severe emotional distress?",
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ]
    },
    {
      question: "Do you have a support system (family, friends, therapist) you can turn to?",
      options: [
        { text: "Yes", value: 0 },
        { text: "No", value: 1 },
      ]
    },
    {
      question: "Are you willing to seek help if you feel you might harm yourself?",
      options: [
        { text: "Yes", value: 0 },
        { text: "No", value: 1 },
      ]
    }
  ];

  return <AssessmentComponent questions={questions} responses={responses} setResponses={setResponses} onComplete={onComplete} />;
};

const NSPLRiskAssessment = ({ responses, setResponses, onComplete }) => {
    const questions = [
      // Suicidal Desire
      {
        question: "Are you having thoughts of suicide?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Do you wish to die or feel like not carrying on?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Do you feel like there is no alternative course of action or way to escape?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Do you feel intolerably alone or experience intense emotional pain?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Do you feel hopeless or helpless about your situation?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Do you perceive yourself as a burden to others?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      
      // Suicidal Capability
      {
        question: "Do you have a plan for how you would harm yourself?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Do you have access to the means (e.g., firearms, medications) to carry out this plan?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Have you made any preparations, such as arranging items or writing notes?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Have you ever attempted to kill yourself before?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Have you experienced any recent dramatic mood changes?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Are you experiencing decreased sleep or chronic insomnia?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
  
      // Suicidal Intent
      {
        question: "Are you currently attempting to hurt yourself?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Do you intend to die?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Have you expressed intent to harm yourself or others?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Have you engaged in any preparatory behaviors (e.g., writing a note, giving away possessions)?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
  
      // Buffers Against Suicidality
      {
        question: "Do you have anyone you can turn to for support right now (family, friends)?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "What are some reasons you have for living?",
        options: [
          { text: "Enter your reasons", value: "", isInput: true }
        ]
      },
      {
        question: "Do you have any future goals or plans?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Are you willing to work with a counselor to create a safety plan?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
      {
        question: "Are you feeling ambivalent about dying or living?",
        options: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ]
      },
    ];
  
    return (
        <AssessmentComponent questions={questions} responses={responses} setResponses={setResponses} onComplete={onComplete} />
    );
  };

  const renderResult = (result, depth = 0) => {
    if (typeof result !== 'object' || result === null) {
      return <span>{result}</span>;
    }

    return (
      <div style={{ marginLeft: `${depth * 20}px` }}>
        {Object.entries(result).map(([key, value]) => (
          <div key={key}>
            <strong>{key.replace(/_/g, ' ')}: </strong>
            {renderResult(value, depth + 1)}
          </div>
        ))}
      </div>
    );
  };

  const AssessmentsPage = () => {
    const [assessment, setAssessment] = useState(null);
    const [responses, setResponses] = useState({});
    const [completed, setCompleted] = useState(false);
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
  
    const assessments = [
      { name: 'MHI', description: 'Mental Health Inventory', component: MHI },
      { name: 'GAF', description: 'Global Assessment of Functioning', component: GAF },
      { name: 'BDI-II', description: 'Beck Depression Inventory-II', component: BDIII },
      { name: 'GAD-7', description: 'Generalized Anxiety Disorder-7', component: GAD7 },
      { name: 'PSS', description: 'Perceived Stress Scale', component: PSS },
      { name: 'C-SSRS', description: 'Columbia-Suicide Severity Rating Scale', component: CSSRS },
      { name: 'SAFE-T', description: 'Suicide Assessment Five-Step Evaluation and Triage', component: SAFET },
      { name: 'NSPL Risk Assessment', description: 'National Suicide Prevention Lifeline Risk Assessment', component: NSPLRiskAssessment },
    ];
  
    useEffect(() => {
      console.log('Current responses:', responses);
    }, [responses]);
  
    const startAssessment = (selectedAssessment) => {
      setAssessment(selectedAssessment);
      setResponses({});
      setCompleted(false);
      setResult(null);
      setMessage(null);
    };
  
    const [isSubmitting, setIsSubmitting] = useState(false);


  const handleComplete = async (finalResponses) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setLoading(true);
    setMessage(null);

    // Get the current assessment's questions
    const currentAssessment = assessments.find(a => a.name === assessment);
    const questions = currentAssessment.component({ responses: finalResponses, setResponses, onComplete: () => {} }).props.questions;

    console.log('Final responses:', finalResponses);
    console.log('Number of questions:', questions.length);

    // Check if all questions are answered
    const unansweredQuestions = questions.filter((_, index) => {
      const questionKey = `q${index + 1}`;
      const response = finalResponses[questionKey];
      console.log(`Checking question ${questionKey}:`, response);
      return response === undefined || response === null || response === '';
    });

    console.log('Unanswered questions:', unansweredQuestions);

    if (unansweredQuestions.length > 0) {
      setLoading(false);
      setIsSubmitting(false);
      setMessage({ type: 'error', text: 'Please answer all questions before submitting.' });
      return;
    }

    // If all questions are answered, proceed with submission
    const payload = {
      assessment_name: assessment,
      responses: finalResponses,
      user_datetime: new Date().toISOString(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    console.log('Payload being sent to server:', payload);

    try {
      const response = await authFetch(API_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error response:', errorData);
        throw new Error(errorData.detail || 'Failed to submit assessment');
      }
      
      const data = await response.json();
      setResult(data.score_result);
      setCompleted(true);
      setMessage({ type: 'success', text: 'Assessment submitted successfully.' });
    } catch (error) {
      console.error('Error submitting assessment:', error);
      setMessage({ type: 'error', text: `Failed to submit assessment: ${error.message}` });
    } finally {
      setLoading(false);
      setIsSubmitting(false);
    }
  };
  
    return (
      <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark p-8">
        <h1 className="text-4xl font-bold mb-12 text-center text-primary-light dark:text-primary-dark">Mental Health Assessments</h1>
        
        <AnimatePresence>
          {message && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className={`p-4 mb-6 rounded-md flex items-center ${
                message.type === 'error' 
                  ? 'bg-accent-light dark:bg-accent-dark' 
                  : 'bg-green-100 dark:bg-green-800'
              } text-text-light dark:text-text-dark`}
            >
              {message.type === 'error' ? (
                <AlertCircle className="mr-2 flex-shrink-0" />
              ) : (
                <FiCheckCircle className="mr-2 flex-shrink-0" />
              )}
              {message.text}
            </motion.div>
          )}
        </AnimatePresence>
  
        <AnimatePresence mode="wait">
          {!assessment ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="max-w-4xl mx-auto grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
            >
              {assessments.map(({ name, description }, index) => (
                <motion.button
                  key={index}
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.97 }}
                  className="bg-secondary-light dark:bg-secondary-dark p-6 rounded-xl shadow-pop-light dark:shadow-pop-dark hover:shadow-lg transition-shadow duration-300 text-left focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark"
                  onClick={() => startAssessment(name)}
                >
                  <h2 className="text-xl font-semibold mb-2 text-primary-light dark:text-primary-dark">{name}</h2>
                  <p className="text-sm text-text-light dark:text-text-dark opacity-75">{description}</p>
                  <FiChevronRight className="mt-4 text-primary-light dark:text-primary-dark" />
                </motion.button>
              ))}
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="max-w-4xl mx-auto bg-secondary-light dark:bg-secondary-dark rounded-xl shadow-pop-light dark:shadow-pop-dark p-8"
            >
              <h2 className="text-3xl font-semibold mb-8 text-center text-primary-light dark:text-primary-dark">{assessment} Assessment</h2>
              {!completed ? (
                React.createElement(assessments.find(a => a.name === assessment).component, {
                  responses,
                  setResponses,
                  onComplete: handleComplete
                })
              ) : (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  className="text-center"
                >
                  {loading ? (
                    <Loader2 className="h-12 w-12 animate-spin text-primary-light dark:text-primary-dark mx-auto" />
                  ) : (
                    <>
                      <FiCheckCircle className="text-6xl text-green-500 mx-auto mb-4" />
                      <h3 className="text-2xl font-semibold mb-4 text-primary-light dark:text-primary-dark">Assessment Completed</h3>
                      {result && (
                        <div className="bg-gray-800 p-6 rounded-lg mt-4">
                          <h3 className="text-xl font-semibold mb-4 text-white">{assessment} results</h3>
                          {renderResult(result)}
                        </div>
                      )}
                      <button
                        onClick={() => setAssessment(null)}
                        className="mt-8 px-6 py-3 bg-primary-light dark:bg-primary-dark text-white rounded-full hover:bg-opacity-90 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark"
                      >
                        Back to Assessments
                      </button>
                    </>
                  )}
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };
  
  export default AssessmentsPage;