import React, { createContext, useState, useEffect } from 'react';
import { refreshIdToken, clearTokens } from '../utils/tokenUtils';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return !!localStorage.getItem('id_token');
  });
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    const checkTokenExpiration = async () => {
      if (isAuthenticated) {
        try {
          await refreshIdToken();
        } catch (error) {
          console.error('Failed to refresh token:', error);
          setIsAuthenticated(false);
          setSessionExpired(true);
          clearTokens();
        }
      }
    };

    checkTokenExpiration();

    const intervalId = setInterval(checkTokenExpiration, 55 * 60 * 1000);

    const handleSessionExpiration = () => {
      setIsAuthenticated(false);
      setSessionExpired(true);
      clearTokens();
    };

    window.addEventListener('session-expired', handleSessionExpiration);

    const handleVisibilityChange = async () => {
      if (!document.hidden && isAuthenticated) {
        try {
          await refreshIdToken();
        } catch (error) {
          console.error('Failed to refresh token on visibility change:', error);
          setIsAuthenticated(false);
          setSessionExpired(true);
          clearTokens();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('session-expired', handleSessionExpiration);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, sessionExpired, setSessionExpired }}>
      {children}
    </AuthContext.Provider>
  );
};
