import React, { useState, useEffect } from 'react';
import { format, parseISO, eachDayOfInterval, startOfYear, endOfYear } from 'date-fns';
import { ChevronLeft, ChevronRight, MessageSquare } from 'lucide-react';
import { authFetch } from '../utils/authFetch';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

const API_BASE_URL = getApiBaseUrl();

const ChatHistoryTimeline = () => {
  const [chatHistory, setChatHistory] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await authFetch(`${API_BASE_URL}/api/em/chatbot/history/`, {
          method: 'GET',
        });
        if (response.ok) {
          const data = await response.json();
          const groupedHistory = groupChatHistoryByDate(data);
          setChatHistory(groupedHistory);
        } else {
          console.error('Failed to fetch chat history');
        }
      } catch (error) {
        console.error('Error fetching chat history:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchChatHistory();
  }, []);

  const groupChatHistoryByDate = (history) => {
    return history.reduce((acc, chat) => {
      const date = format(parseISO(chat.timestamp), 'yyyy-MM-dd');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(chat);
      return acc;
    }, {});
  };

  const daysInYear = eachDayOfInterval({
    start: startOfYear(new Date(currentYear, 0, 1)),
    end: endOfYear(new Date(currentYear, 11, 31)),
  });

  const changeYear = (increment) => {
    setCurrentYear(prev => prev + increment);
    setSelectedDate(null); // Reset selected date when changing year
  };

  const selectDate = (date) => {
    setSelectedDate(prevDate => (prevDate === date ? null : date));
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-background-light dark:bg-black">
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-light dark:border-primary-dark"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background-light dark:bg-black text-text-light dark:text-text-dark p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-12">
          <h3 className="text-5xl font-bold text-primary-light dark:text-primary-dark 
                         neon-effect-primary">Chat History Timeline</h3>
          <div className="flex items-center space-x-6">
            <button onClick={() => changeYear(-1)} className="text-xl text-primary-light dark:text-primary-dark 
                                                              hover:text-accent-light dark:hover:text-accent-dark 
                                                              transition-colors duration-200 neon-effect-secondary">
              <ChevronLeft />
            </button>
            <span className="text-4xl font-semibold text-primary-light dark:text-primary-dark 
                             neon-effect-primary">{currentYear}</span>
            <button onClick={() => changeYear(1)} className="text-3xl text-primary-light dark:text-primary-dark 
                                                             hover:text-accent-light dark:hover:text-accent-dark 
                                                             transition-colors duration-200 neon-effect-secondary">
              <ChevronRight />
            </button>
          </div>
        </div>

        <div className="relative">
          <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-primary-light dark:bg-primary-dark 
                          opacity-30 transform -translate-x-1/2"></div>
          <div className="space-y-8">
            {daysInYear.map(day => {
              const dateString = format(day, 'yyyy-MM-dd');
              const hasChats = chatHistory[dateString] && chatHistory[dateString].length > 0;
              if (!hasChats) return null;
              
              return (
                <div key={dateString} className={`flex items-center ${format(day, 'M') % 2 === 0 ? 'flex-row-reverse' : ''}`}>
                  <div className={`w-1/2 ${format(day, 'M') % 2 === 0 ? 'text-right' : 'text-left'}`}>
                    <button
                      onClick={() => selectDate(dateString)}
                      className={`inline-block px-6 py-3 rounded-full transition-all duration-300 transform hover:scale-105 
                                  ${selectedDate === dateString
                                    ? 'bg-primary-light dark:bg-primary-dark text-text-dark dark:text-text-light neon-effect-primary'
                                    : 'bg-secondary-light dark:bg-secondary-dark hover:bg-accent-light dark:hover:bg-accent-dark text-text-light dark:text-text-dark neon-effect-secondary'
                                  }`}
                    >
                      <span className="font-semibold">{format(day, 'MMM d')}</span>
                      <MessageSquare className="inline-block ml-2 h-5 w-5" />
                    </button>
                  </div>
                  <div className="w-4 h-4 bg-primary-light dark:bg-primary-dark rounded-full absolute left-1/2 transform -translate-x-1/2 neon-effect-primary"></div>
                  {selectedDate === dateString && (
                    <div className={`w-1/2 ${format(day, 'M') % 2 === 0 ? 'pl-8' : 'pr-8'}`}>
                      <div className="bg-tertiary-light dark:bg-tertiary-dark rounded-lg p-6 shadow-pop-light dark:shadow-pop-dark">
                        {chatHistory[dateString].map((chat, index) => (
                          <div key={index} className="mb-4 last:mb-0">
                            <p className="font-semibold mb-1 text-primary-light dark:text-primary-dark">You: {chat.user_message}</p>
                            <p className="text-text-light dark:text-text-dark">Bot: {chat.bot_response}</p>
                            <p className="text-xs text-text-light dark:text-text-dark opacity-70 mt-1">
                              {format(parseISO(chat.timestamp), 'h:mm a')}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatHistoryTimeline;