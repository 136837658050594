import React from 'react';
import { useNavigate } from 'react-router-dom';

const SessionExpiredPopup = ({ onClose }) => {
  const navigate = useNavigate();

  const handleLogin = () => {
    onClose();
    navigate('/login');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Session Expired</h2>
        <p className="mb-4">Your session has expired. Please log in again to continue.</p>
        <button
          onClick={handleLogin}
          className="bg-primary-light text-white px-4 py-2 rounded hover:bg-primary-dark"
        >
          Log In
        </button>
      </div>
    </div>
  );
};

export default SessionExpiredPopup;
