import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { authFetch } from '../utils/authFetch';
import { useLocation } from 'react-router-dom';
import { Settings as SettingsIcon, Music, Bell, Moon, Sun, CheckCircle, XCircle, Loader2 } from 'lucide-react';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

const Settings = () => {
  const [spotifyLinked, setSpotifyLinked] = useState(false);
  const [linkingInProgress, setLinkingInProgress] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [message, setMessage] = useState(null);
  const location = useLocation();
  const API_BASE_URL = getApiBaseUrl();

  useEffect(() => {
    checkSpotifyLink();
    checkNotificationSettings();
    checkDarkModeSettings();
  }, []);

  const checkSpotifyLink = async () => {
    try {
      const response = await authFetch(`${API_BASE_URL}/api/em/check_spotify_link/`);
      const data = await response.json();
      if (response.ok) {
        setSpotifyLinked(data.spotify_linked);
      }
    } catch (error) {
      console.error('Error checking Spotify link:', error);
      showMessage('Error checking Spotify link.', 'error');
    }
  };

  const handleLinkSpotify = async () => {
    setLinkingInProgress(true);
    try {
      const response = await authFetch(`${API_BASE_URL}/api/em/link_spotify/`, {
        method: 'POST',
        body: JSON.stringify({ context: 'settings' }),
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      if (response.ok) {
        window.location.href = data.auth_url;
      } else {
        throw new Error(data.error || 'Error linking Spotify.');
      }
    } catch (error) {
      console.error('Error linking Spotify:', error);
      showMessage('Error linking Spotify. Please try again.', 'error');
    } finally {
      setLinkingInProgress(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    if (code) {
      const exchangeCode = async () => {
        try {
          const response = await authFetch(`${API_BASE_URL}/api/em/spotify_callback/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code, state }),
          });
          const data = await response.json();
          if (response.ok) {
            setSpotifyLinked(true);
            showMessage('Spotify linked successfully!', 'success');
          } else {
            throw new Error(data.error || 'Error linking Spotify.');
          }
        } catch (error) {
          console.error('Error exchanging code:', error);
          showMessage('Error linking Spotify. Please try again.', 'error');
        } finally {
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      };
      exchangeCode();
    }
  }, [location]);

  const checkNotificationSettings = async () => {
    // Implement this function to fetch notification settings from your backend
    // For now, we'll use a placeholder
    setNotificationsEnabled(false);
  };

  const checkDarkModeSettings = () => {
    const isDarkMode = document.documentElement.classList.contains('dark');
    setDarkMode(isDarkMode);
  };

  const toggleNotifications = async () => {
    // Implement this function to toggle notifications on your backend
    // For now, we'll just toggle the state
    setNotificationsEnabled(!notificationsEnabled);
    showMessage(`Notifications ${!notificationsEnabled ? 'enabled' : 'disabled'}.`, 'success');
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    document.documentElement.classList.toggle('dark', newDarkMode);
    showMessage(`${newDarkMode ? 'Dark' : 'Light'} mode activated.`, 'success');
  };

  const showMessage = (text, type) => {
    setMessage({ text, type });
    setTimeout(() => setMessage(null), 5000);
  };

  const SettingItem = ({ title, description, icon: Icon, action, actionText, enabled }) => (
    <div className="flex items-center justify-between p-4 bg-secondary-light dark:bg-secondary-dark rounded-lg mb-4">
      <div className="flex items-center">
        <Icon className="text-primary-light dark:text-primary-dark mr-4" size={24} />
        <div>
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className="text-sm text-gray-600 dark:text-gray-400">{description}</p>
        </div>
      </div>
      <button
        onClick={action}
        className={`px-4 py-2 rounded-full ${
          enabled
            ? 'bg-primary-light dark:bg-primary-dark text-white'
            : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200'
        }`}
        disabled={linkingInProgress && title === 'Spotify Account'}
      >
        {linkingInProgress && title === 'Spotify Account' ? (
          <Loader2 className="animate-spin" size={20} />
        ) : (
          actionText
        )}
      </button>
    </div>
  );

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark p-8">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 flex items-center">
          <SettingsIcon className="mr-2" /> Settings
        </h1>

        <AnimatePresence>
          {message && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className={`p-4 mb-4 rounded-lg flex items-center ${
                message.type === 'error' ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
              }`}
            >
              {message.type === 'error' ? <XCircle className="mr-2" /> : <CheckCircle className="mr-2" />}
              {message.text}
            </motion.div>
          )}
        </AnimatePresence>

        <SettingItem
          title="Spotify Account"
          description="Link your Spotify account to personalize music playlists based on your mood."
          icon={Music}
          action={handleLinkSpotify}
          actionText={spotifyLinked ? 'Unlink' : 'Link Spotify'}
          enabled={spotifyLinked}
        />

        <SettingItem
          title="Notifications"
          description="Receive reminders and updates about your mental health journey."
          icon={Bell}
          action={toggleNotifications}
          actionText={notificationsEnabled ? 'Disable' : 'Enable'}
          enabled={notificationsEnabled}
        />

        <SettingItem
          title="Dark Mode"
          description="Switch between light and dark theme for the app."
          icon={darkMode ? Sun : Moon}
          action={toggleDarkMode}
          actionText={darkMode ? 'Light Mode' : 'Dark Mode'}
          enabled={darkMode}
        />
      </div>
    </div>
  );
};

export default Settings;