import React, { useState, useEffect, useCallback } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import { useSpring, animated } from 'react-spring';

const emotions = {
  fear: {
    color: '#8B00FF',
    secondary: ['embarrassed', 'unwanted', 'inferior', 'insecure', 'anxious', 'scared'],
    tertiary: ['tender', 'blushing', 'unsteady', 'cold', 'tense', 'frozen', 'quiet', 'racing heart', 'foot-tapping', 'fidgety', 'numb hands', 'trembling']
  },
  surprise: {
    color: '#FFA500',
    secondary: ['shock', 'confusion', 'awe', 'excitement'],
    tertiary: ['jumpy', 'sweaty palms', 'breathless', 'speechless', 'jaw drop', 'eyebrows up', 'electrified', 'jumpy']
  },
  happy: {
    color: '#FFFF00',
    secondary: ['joy', 'curious', 'proud', 'satisfied', 'courageous', 'peaceful', 'intimate', 'optimistic'],
    tertiary: ['open', 'energetic', 'awake', 'brown-furrow', 'inflated', 'tall', 'soft', 'calm', 'jaw set', 'steady', 'relaxed', 'still', 'sensitive', 'warm', 'light', 'buzzing']
  },
  sad: {
    color: '#0000FF',
    secondary: ['guilt', 'abandoned', 'despair', 'depressed', 'lonely', 'apathetic'],
    tertiary: ['looking down', 'empty', 'curling up', 'slouching', 'crying', 'body aches', 'tiredness', 'hollow feeling', 'slow heart', 'heaviness', 'weak', 'eye rolls']
  },
  disgust: {
    color: '#008000',
    secondary: ['disapproval', 'disappointed', 'awful', 'aversion'],
    tertiary: ['shuddering', 'writhing', 'need to move', 'face-scrunched', 'nausea', 'lump in throat', 'queasy', 'turn away']
  },
  anger: {
    color: '#FF0000',
    secondary: ['hurt', 'insecure', 'hateful', 'mad', 'aggressive', 'irritate', 'distant', 'critical'],
    tertiary: ['lip-tremble', 'limp', 'hiding', 'hot', 'scowl', 'turning away', 'loud words', 'flushed', 'heart racing', 'clenching', 'tight jaw', 'headache', 'numb', 'gut-turning', 'feeling hot', 'lip curled']
  }
};

const EmotionWeb = () => {
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);

  useEffect(() => {
    const nodes = [];
    const links = [];
    
    Object.entries(emotions).forEach(([primary, data]) => {
      nodes.push({ id: primary, group: 'primary', val: 20 });
      
      data.secondary.forEach(secondary => {
        nodes.push({ id: secondary, group: 'secondary', val: 10, primaryEmotion: primary });
        links.push({ source: primary, target: secondary });
        
        data.tertiary.forEach(tertiary => {
          nodes.push({ id: tertiary, group: 'tertiary', val: 5, primaryEmotion: primary });
          links.push({ source: secondary, target: tertiary });
        });
      });
    });
    
    setGraphData({ nodes, links });
  }, []);

  const handleNodeHover = useCallback((node) => {
    setHighlightNodes(new Set(node ? [node] : []));
    setHighlightLinks(new Set(graphData.links.filter(link => link.source === node || link.target === node)));
    setHoverNode(node || null);
  }, [graphData.links]);

  const handleNodeClick = useCallback((node) => {
    setSelectedNode(node);
  }, []);

  const getNodeColor = useCallback((node) => {
    const baseColor = node.primaryEmotion ? emotions[node.primaryEmotion].color : emotions[node.id].color;
    return highlightNodes.has(node) ? baseColor : `${baseColor}80`;
  }, [highlightNodes]);

  const NodeLabel = ({ node }) => {
    const springProps = useSpring({
      opacity: hoverNode === node ? 1 : 0,
      transform: `scale(${hoverNode === node ? 1 : 0.5})`,
    });

    return (
      <animated.div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          background: 'rgba(0,0,0,0.8)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '4px',
          ...springProps,
        }}
      >
        <strong>{node.id}</strong> ({node.group})
      </animated.div>
    );
  };

  return (
    <div className="h-screen w-full bg-gray-900 flex flex-col items-center justify-center relative overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-purple-900 to-blue-900 opacity-50" />
      <h1 className="text-4xl font-bold mb-4 text-white z-10" style={{ fontFamily: "'Press Start 2P', cursive" }}>Emotion Web</h1>
      <div className="w-full h-[80vh] relative">
        <ForceGraph2D
          graphData={graphData}
          nodeRelSize={6}
          nodeVal={node => node.val * (highlightNodes.has(node) ? 1.5 : 1)}
          nodeColor={getNodeColor}
          linkWidth={link => highlightLinks.has(link) ? 2 : 1}
          linkColor={link => highlightLinks.has(link) ? "#ffffff" : "#ffffff40"}
          linkDirectionalParticles={4}
          linkDirectionalParticleSpeed={0.001}
          nodeCanvasObjectMode={() => 'after'}
          nodeCanvasObject={(node, ctx, globalScale) => {
            const label = node.id;
            const fontSize = node.group === 'primary' ? 14 : node.group === 'secondary' ? 12 : 10;
            ctx.font = `${fontSize}px 'Press Start 2P'`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = highlightNodes.has(node) ? 'white' : 'rgba(255,255,255,0.7)';
            ctx.fillText(label, node.x, node.y);
          }}
          onNodeHover={handleNodeHover}
          onNodeClick={handleNodeClick}
          cooldownTicks={100}
        />
      </div>
      {hoverNode && <NodeLabel node={hoverNode} />}
      {selectedNode && (
        <div className="absolute bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg z-20">
          <h2 className="text-xl font-bold mb-2">{selectedNode.id}</h2>
          <p><strong>Type:</strong> {selectedNode.group}</p>
          {selectedNode.primaryEmotion && <p><strong>Primary Emotion:</strong> {selectedNode.primaryEmotion}</p>}
        </div>
      )}
    </div>
  );
};

export default EmotionWeb;