import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getApiBaseUrl } from './apiBaseUrl';

let FIREBASE_API_KEY;
let firebaseApiKeyPromise;

const fetchFirebaseApiKey = async () => {
  try {
    console.log('Fetching Firebase API key...');
    const response = await fetch(`${getApiBaseUrl()}/api/firebase-api-key/`);
    console.log('API Response status:', response.status);
    
    if (!response.ok) {
      throw new Error(`Failed to fetch Firebase API key: ${response.status}`);
    }
    
    const data = await response.json();
    
    if (!data.firebase_api_key) {
      throw new Error('API key is empty in response');
    }
    
    FIREBASE_API_KEY = data.firebase_api_key;
    return data.firebase_api_key;
  } catch (error) {
    console.error('Error in fetchFirebaseApiKey:', error);
    throw error;
  }
};

firebaseApiKeyPromise = fetchFirebaseApiKey();

export const refreshIdToken = async () => {
  try {
    FIREBASE_API_KEY = await firebaseApiKeyPromise;
    
    if (!FIREBASE_API_KEY) {
      throw new Error('Firebase API key not available');
    }

    const refresh_token = localStorage.getItem('refresh_token');
    if (!refresh_token) {
      throw new Error('No refresh token available');
    }

    const response = await fetch(`https://securetoken.googleapis.com/v1/token?key=${FIREBASE_API_KEY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=refresh_token&refresh_token=${refresh_token}`,
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      console.error('Token refresh failed:', {
        status: response.status,
        statusText: response.statusText,
        error: errorData
      });
      throw new Error(`Failed to refresh token: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    localStorage.setItem('id_token', data.id_token);
    localStorage.setItem('refresh_token', data.refresh_token);

    return data.id_token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export const clearTokens = () => {
  localStorage.removeItem('id_token');
  localStorage.removeItem('refresh_token');
};