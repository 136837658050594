import React, { useState, useEffect, useCallback } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { motion, AnimatePresence } from 'framer-motion';
import { authFetch } from '../utils/authFetch';
import { AlertCircle } from 'lucide-react';
import { getApiBaseUrl } from '../utils/apiBaseUrl';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const emotions = {
  fear: { color: '#8B00FF' },
  surprise: { color: '#FFA500' },
  happy: { color: '#FFFF00' },
  sad: { color: '#0000FF' },
  disgust: { color: '#008000' },
  anger: { color: '#FF0000' },
};

const getEmotionColor = (intensity) => {
  const emotionEntries = Object.entries(emotions);
  const index = Math.floor((intensity - 1) / (10 / emotionEntries.length));
  return emotionEntries[Math.min(index, emotionEntries.length - 1)][1].color;
};

const Dashboard = () => {
  const [moodData, setMoodData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', content: '' });
  const API_BASE_URL = getApiBaseUrl();

  const fetchMoodData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await authFetch(`${API_BASE_URL}/api/users/mood-chart/`);
      if (response.ok) {
        const data = await response.json();
        setMoodData(data);
      } else {
        const errorData = await response.json();
        setMessage({ type: 'error', content: 'Failed to fetch mood data. Please try again.' });
      }
    } catch (error) {
      setMessage({ type: 'error', content: 'Error fetching mood data. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    fetchMoodData();
  }, [fetchMoodData]);

  const createChartData = (data, label, timeFrame) => {
    if (!data || data.length === 0) {
      return {
        labels: [],
        datasets: [{
          label,
          data: [],
        }]
      };
    }

    return {
      labels: data.map(d => {
        if (timeFrame === 'daily') return new Date(d.date).toLocaleDateString();
        if (timeFrame === 'weekly') return `Week ${d.week + 1}`;
        if (timeFrame === 'monthly') return d.month;
        return '';
      }),
      datasets: [
        {
          label,
          data: data.map(d => d.avg_intensity),
          borderColor: data.map(d => getEmotionColor(d.avg_intensity)),
          backgroundColor: data.map(d => `${getEmotionColor(d.avg_intensity)}80`),
          tension: 0.1,
          fill: false,
          pointBackgroundColor: data.map(d => getEmotionColor(d.avg_intensity)),
          pointRadius: 5,
          pointHoverRadius: 7,
        },
      ],
    };
  };

  const dailyChartData = createChartData(moodData?.daily, 'Daily Mood', 'daily');
  const weeklyChartData = createChartData(moodData?.weekly, 'Weekly Mood', 'weekly');
  const monthlyChartData = createChartData(moodData?.monthly, 'Monthly Mood', 'monthly');

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Mood Trends',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataPoint = context.raw;
            const dataIndex = context.dataIndex;
            let chartData;

            if (context.dataset.label.includes('Daily')) {
              chartData = moodData.daily;
            } else if (context.dataset.label.includes('Weekly')) {
              chartData = moodData.weekly;
            } else if (context.dataset.label.includes('Monthly')) {
              chartData = moodData.monthly;
            }

            if (!chartData || !chartData[dataIndex]) {
              return `${context.dataset.label}: No data`;
            }

            const entry = chartData[dataIndex];
            const mostFrequentEmotion = entry.most_frequent_emotion;
            
            let tooltipText = [`${context.dataset.label}: ${dataPoint.toFixed(2)} (${mostFrequentEmotion})`];
            tooltipText.push('All emotions:');
            if (Array.isArray(entry.all_moods)) {
              entry.all_moods.forEach(mood => {
                tooltipText.push(`${mood.emotion}: ${mood.intensity}`);
              });
            } else {
              tooltipText.push('No mood data available');
            }
            
            return tooltipText;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 10,
        title: {
          display: true,
          text: 'Mood Intensity',
        },
      },
    },
  };

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark p-4 sm:p-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-6xl mx-auto"
      >
        <h1 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center text-primary-light dark:text-primary-dark">Mood Dashboard</h1>
        <AnimatePresence>
          {message.content && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className={`mb-4 p-3 rounded-lg flex items-center ${
                message.type === 'error' ? 'bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-200' : 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-200'
              }`}
            >
              <AlertCircle size={20} className="mr-2 flex-shrink-0" />
              <p>{message.content}</p>
            </motion.div>
          )}
        </AnimatePresence>
        {isLoading ? (
          <p className="text-center">Loading mood data...</p>
        ) : moodData ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="bg-secondary-light dark:bg-secondary-dark shadow-pop-light dark:shadow-pop-dark rounded-xl p-4"
            >
              <h2 className="text-xl font-semibold mb-4">Daily Mood (Last 7 Days)</h2>
              <Line data={dailyChartData} options={chartOptions} id="daily" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="bg-secondary-light dark:bg-secondary-dark shadow-pop-light dark:shadow-pop-dark rounded-xl p-4"
            >
              <h2 className="text-xl font-semibold mb-4">Weekly Mood (Last 4 Weeks)</h2>
              <Bar data={weeklyChartData} options={chartOptions} id="weekly" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-secondary-light dark:bg-secondary-dark shadow-pop-light dark:shadow-pop-dark rounded-xl p-4 md:col-span-2"
            >
              <h2 className="text-xl font-semibold mb-4">Monthly Mood (Last 12 Months)</h2>
              <Line data={monthlyChartData} options={chartOptions} id="monthly" />
            </motion.div>
            {moodData.yearly && (
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="bg-secondary-light dark:bg-secondary-dark shadow-pop-light dark:shadow-pop-dark rounded-xl p-4 md:col-span-2"
              >
                <h2 className="text-xl font-semibold mb-4">Yearly Mood Average</h2>
                <div className="mood-meter h-8 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                  <div
                    className="h-full"
                    style={{ 
                      width: `${moodData.yearly.avg_intensity * 10}%`,
                      backgroundColor: getEmotionColor(moodData.yearly.avg_intensity)
                    }}
                  ></div>
                </div>
                <p className="text-center mt-2 font-semibold">
                  {moodData.yearly.avg_intensity.toFixed(2)} - {moodData.yearly.most_frequent_emotion}
                </p>
              </motion.div>
            )}
          </div>
        ) : (
          <p className="text-center">No mood data available.</p>
        )}
      </motion.div>
    </div>
  );
};

export default Dashboard;